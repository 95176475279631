import React from 'react';
import * as PropTypes from 'prop-types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from './Button';
import { DELETE } from '../../abilities/actions';
import Confirmation from './Confirmation';
import { SubjectPropType } from '../../abilities/proptypes';

const DeleteButton = ({ onClick, confirmLabel, disabled, subject,hidden }) => (
    <Confirmation popupId="DeleteButton" onConfirm={onClick} label={confirmLabel || 'Löschen?'}>
        <Button
            color="danger"
            startIcon={<DeleteForeverIcon />}
            disabled={disabled}
            action={DELETE}
            subject={subject}
            hidden={hidden}
        >
            Löschen
        </Button>
    </Confirmation>
);

DeleteButton.propTypes = {
    confirmLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
};

DeleteButton.defaultProps = {
    confirmLabel: null,
    disabled: false,
    subject: null,
    hidden: false,
};

export default DeleteButton;

import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ContactName from './ContactName';
import Country from '../../countries/components/Country';
import { IdPropType } from '../../../proptypes/basic';
import { selectContactById } from '../contactsSlice';

const ContactInfo = ({ contactId, secondContactId }) => {
    const contact = useSelector((state) => selectContactById(state, contactId));
    const secondContact = useSelector((state) => selectContactById(state, secondContactId));

    return contact ? (
        <Typography variant="body2">
            {contact.company && (
                <>
                    {contact.company}
                    <br />
                </>
            )}
            {(contact.firstname || contact.lastname) && (
                <>
                    <ContactName contactId={contact.id} />
                    <br />
                </>
            )}
            {secondContact && (secondContact.firstname || secondContact.lastname) && (
                <>
                    <ContactName contactId={secondContact.id} />
                    <br />
                </>
            )}
            {contact.address && (
                <>
                    {contact.address}
                    <br />
                </>
            )}
            {contact.zip} {contact.city}
            <br />
            {contact.country && contact.country !== 'deu' && <Country alpha3={contact.country} />}
        </Typography>
    ) : null;
};

ContactInfo.propTypes = {
    contactId: IdPropType.isRequired,
    secondContactId: IdPropType,
};

export default ContactInfo;

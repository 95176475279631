import React, { useCallback, useMemo } from 'react';
import { IdPropType } from 'proptypes/basic';
import { useDispatch } from 'react-redux';
import Button from '../../buttons/components/Button';
import { updateCustomer, useCustomer } from '../customersSlice';
import { useSnackbar } from '../../snackbar/hooks';
import { useContactsByCustomerId } from '../../contacts/contactsSlice';
import { useContractsByCustomerId } from '../../contracts/contractsSlice';
import { useVisitsByCustomerId } from '../../visits/visitsSlice';
import { useLatestQuestionnaireByContactId } from '../../questionnaires/hooks';
import { PZH_CONTRACT, AGENCY_CONTRACT } from '../../contracts/contractTypes';

const CustomerSetEmptyEmployee = ({ customerId, disabled }) => {
    const [customer] = useCustomer(customerId);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const currentEmployeeId = customer.employeeId;

    // ASSIGNING CHECKS - START
    const [contacts] = useContactsByCustomerId(customerId);
    const patientContact = useMemo(() => {
        const cs = contacts.filter((c) => c.isPatient);
        if (cs && cs.length > 0) return cs[0];
        return null;
    }, [contacts]);
    const patientContactId = patientContact !== null ? patientContact.id : null;
    const [questionnaire] = useLatestQuestionnaireByContactId(patientContactId);

    const [contracts] = useContractsByCustomerId(customerId);
    const [visits] = useVisitsByCustomerId(customerId);

    const bkAssignError = useMemo(() => {
        let error = [];

        if (!customer?.icQualified) {
            error.push('Kunde nicht qualifiziert');
        }

        // Beratung vor Ort check
        if (!customer?.onsiteConsultedAt) {
            error.push('Keine Beratung vor Ort durchgeführt');
        }

        if (!questionnaire) {
            error.push('Keine Erhebungsbogen erstellt');
        }

        // anamnesis check
        const conductedBy = questionnaire?.anamnesisData?.meta?.employee;
        //const AnamnesisBy = conductedBy ? conductedBy : null;
        if (!conductedBy) {
            error.push('Keine Anamnese durchgeführt');
        }

        // Emergency contact check
        const ecs = contacts.filter((c) => c.isEmergencyContact);
        if (!ecs || (ecs && ecs.length === 0)) {
            error.push('Kein Notfallkontakt definiert');
        }

        // BK Vorschläge
        const bcs = contacts.filter((c) => c.sendNurseSuggestions);
        if (!bcs || (bcs && bcs.length === 0)) {
            error.push('Kein Kontakt definiert, der BK-Vorschläge erhält');
        }

        // Vertragspartner
        const ccs = contacts.filter((c) => c.isContractingPartner);
        if (!ccs || (ccs && ccs.length === 0)) {
            error.push('Kein Vertragspartner definiert');
        }

        // Rechnungsaddresse
        const cis = contacts.filter((c) => c.isInvoiceAddress);
        if (!cis || (cis && cis.length === 0)) {
            error.push('Keine Rechnungsversandadresse definiert');
        }

        // Vertrag Checks
        if (!contracts) {
            error.push('Ein PzH-Vertrag muss vorliegen');
            error.push('Ein DLV muss vorliegen');
        } else {
            const acc = contracts.filter(
                (c) => c.type === AGENCY_CONTRACT && c.completeness === 'complete'
            );
            if (!acc || (acc && acc.length === 0)) {
                error.push('Ein DLV muss vorliegen');
            }
            const pcc = contracts.filter(
                (c) => c.type === PZH_CONTRACT && c.completeness === 'complete'
            );
            if (!pcc || (pcc && pcc.length === 0)) {
                error.push('Ein PzH-Vertrag muss vorliegen');
            }
        }

        // CHECK visits
        if (!visits || (visits && visits.length === 0)) {
            error.push('Es muss eine Visite erfolgt sein');
        }

        return error;
    }, [contacts, contracts, questionnaire, visits, customer]);
    // ASSIGNING CHECKS - END

    // TODO: use v3
    // import ListDialog from '../../dialogs/components/ListDialog';
    //            <ListDialog
    //                list={bkAssignError}
    //                title="Zuweisung nicht möglich - Bitte folgende Punkte prüfen"
    //            />
    const handleSubmit = useCallback(async () => {
        if (bkAssignError.length > 0) {
            enqueueSnackbar('- ' + bkAssignError.join('\n- '), {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
            });
            return Promise.resolve();
        }

        return dispatch(
            updateCustomer({
                id: customerId,
                employeeId: null,
                employeeFormerId: currentEmployeeId,
            })
        );
    }, [dispatch, customerId, enqueueSnackbar, currentEmployeeId, bkAssignError]);

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={(customer && customer.employeeFormerId) || disabled}
        >
            An BK-Team übergeben
        </Button>
    );
};

CustomerSetEmptyEmployee.propTypes = {
    customerId: IdPropType.isRequired,
};

CustomerSetEmptyEmployee.defaultProps = {};

export default CustomerSetEmptyEmployee;

import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import TitleBar from '../../layout/components/TitleBar';
import Typography from '../../mui/Components/Typography';
import ToggleButtons from '../../form/components/ToggleButtons';
import TextInput from '../../form/components/TextInput';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import DateInput from '../../form/components/DateInput';
import Checkbox from '../../form/components/Checkbox';
import CheckInOutDisable from './CheckInOutDisable';
import { useCustomer } from '../../customers/customersSlice';
import {
    showNurseDeployment,
    storeNurseDeployment,
    updateNurseDeployment,
    useNurseDeployment,
} from '../nurseDeploymentsSlice';
import NursePriceFieldView from './NursePriceFieldView';
import A1DocumentList from '../../a1document/components/A1DocumentList';
import ContractSelect from '../../contracts/components/ContractSelect';
import CareNeedSelect from '../../careNeeds/components/CareNeedSelect';
import { destroyA1Document, storeA1Document } from '../../a1document/a1DocumentsSlice';

import { NURSE_DEPLOYMENT_RESOURCE } from '../../api/resources';
import { useCareNeedsByCustomerId } from '../../careNeeds/careNeedsSlice';
import { useInitialValues } from '../../form/hooks';
import { nurseDeploymentSchema } from '../schema';
import NurseAutocomplete from '../../form/components/specialized/autocomplete/NurseAutocomplete';
import AgencyByContractSelect from './AgencyByContractSelect';
import { useUser } from '../../auth/hooks';
import ContractEndAt from '../../contracts/components/ContractEndAt.js';

const DEPLOYMENT_TYPES = [
    { value: 'nurse', label: 'BK' },
    { value: 'pause', label: 'Pause' },
    { value: 'cancellation_period', label: 'Kündigungsfrist' },
];

const submitFull = async (
    values,
    deleteA1Docs,
    nurseDeploymentId,
    customerId,
    employeeId,
    copy,
    dispatch
) => {
    const prepped = { ...values, id: copy ? null : nurseDeploymentId, customerId };

    if (!copy) {
        await Promise.all(
            deleteA1Docs.map((id) => {
                return dispatch(destroyA1Document({ id }));
            })
        );
    }

    const result = await dispatch(
        nurseDeploymentId && !copy ? updateNurseDeployment(prepped) : storeNurseDeployment(prepped)
    );

    const { a1From, a1Till } = values;
    if (a1From && a1Till) {
        await dispatch(
            storeA1Document({
                nurseDeploymentId: result.data.id,
                dateFrom: a1From,
                dateTill: a1Till,
                acceptedManuallyAt: new Date(),
                acceptedManuallyBy: employeeId,
            })
        );

        /* a1State has probably changed -> reload nurse deployment...
         * TODO: should this be handled by sockets automatically?
         */
        return dispatch(showNurseDeployment({ id: result.data.id }));
    }

    return result;
};

const NurseDeploymentForm = ({
    careNeedId,
    nurseDeploymentId,
    customerId: customerIdOverride,
    copy,
    onDone,
    submitRef,
}) => {
    const dispatch = useDispatch();
    const [deleteA1DocList, setDeleteA1DocList] = useState([]);
    const [nurseDeployment] = useNurseDeployment(nurseDeploymentId);
    const customerId = customerIdOverride || (nurseDeployment && nurseDeployment.customerId);
    const customer = useCustomer(customerId);
    const careNeeds = useCareNeedsByCustomerId(customerId);
    const user = useUser();

    const initialValues = useInitialValues(nurseDeployment, {
        careNeedId: (careNeeds.length === 1 && careNeeds[0].id) || careNeedId || '',
        customerContractId: '',
        customerContractEndAt: '',
        agencyId: '',
        type: 'nurse',
        nurseId: '',
        careFrom: null,
        careTill: null,
        notes: '',
        arrivalAt: null,
        departureAt: null,
        withoutArrTransfer: false,
        withoutDepTransfer: false,
        a1From: null,
        a1Till: null,
        billingFrom: null,
        billingFromHalf: false,
        billingTill: null,
        billingTillHalf: false,
        priceAgency: '',
        priceAgencyDaily: false,
        surchargeAgency: '',
        a1Notes: '',
        provisionPZH: '',
        surchargePZH: '',
        provisionLocation: '',
        provisionMultiplier: '', // FIXME: missing in form
    });

    const handleSubmit = useCallback(
        (values) =>
            submitFull(
                values,
                deleteA1DocList,
                nurseDeploymentId,
                customerId,
                user.employeeId,
                copy,
                dispatch
            ).then(onDone),
        [dispatch, nurseDeploymentId, customerId, copy, deleteA1DocList, onDone, user.employeeId]
    );

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            subject={nurseDeployment || NURSE_DEPLOYMENT_RESOURCE}
            validationSchema={nurseDeploymentSchema}
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ToggleButtons name="type" options={DEPLOYMENT_TYPES} exclusive />
                </Grid>
                {careNeeds.length > 1 && (
                    <Grid item xs={3}>
                        <CareNeedSelect
                            label="BK-Bedarf"
                            name="careNeedId"
                            customerId={customerId}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <ContractSelect
                        label="Vertrag"
                        name="customerContractId"
                        customerId={customerId}
                    />
                    <AgencyByContractSelect name="agencyId" contractName="customerContractId" />
                    <Box component="span" display="none">
                        <ContractEndAt
                            name="customerContractEndAt"
                            contractName="customerContractId"
                        />
                    </Box>
                </Grid>

                <ConditionalField
                    conditionFieldName="type"
                    condition={(value) => value === 'nurse'}
                >
                    <Grid item xs={6}>
                        <NurseAutocomplete name="nurseId" label="Betreuungskraft" fullWidth />
                    </Grid>
                </ConditionalField>

                <Grid item xs={12}>
                    <Box mt={5}>
                        <TitleBar>Einsatz</TitleBar>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="careFrom" label="Betreuung von" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="careTill" label="Betreuung bis" fullWidth />
                </Grid>
                <Grid item xs={6} />
                <CheckInOutDisable
                    deploymentId={nurseDeploymentId}
                    nameArr="withoutArrTransfer"
                    labelArr="Anreise nicht erforderlich"
                    nameDep="withoutDepTransfer"
                    labelDep="Abreise nicht erforderlich"
                    xs={3}
                />

                <Grid item xs={12}>
                    <Box mt={5}>
                        <TitleBar>Kosten</TitleBar>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <TextInput
                        name="priceAgency"
                        label="Preis Agentur"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="surchargeAgency"
                        label="Zuschlag Agentur"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="provisionPZH"
                        label="Provision PZH"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="surchargePZH"
                        label="Zuschlag PZH"
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <ToggleButtons
                        name="priceAgencyDaily"
                        options={[
                            { value: true, label: 'pro Tag' },
                            { value: false, label: 'pro Monat' },
                        ]}
                        exclusive
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography>Endpreis Kunde</Typography>
                    <NursePriceFieldView />
                </Grid>
                <Grid item xs={3}>
                    <TextInput
                        name="provisionLocation"
                        label={
                            customer.multiplierId
                                ? 'davon Provi. Pflegedienst-Partner'
                                : 'davon Provi. Standort'
                        }
                        type="number"
                        money
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="billingFrom" label="Berechnung vom" fullWidth />
                    <Checkbox name="billingFromHalf" label="Anreise halber Tag" />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="billingTill" label="Berechnung bis" fullWidth />
                    <Checkbox name="billingTillHalf" label="Abreise halber Tag" />
                </Grid>

                <Grid item xs={12}>
                    <Box mt={5}>
                        <TitleBar>Für folgenden Zeitraum wurden A1-Bescheinigungen nachgewiesen</TitleBar>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="a1From" label="A1 vom" fullWidth />
                </Grid>
                <Grid item xs={3}>
                    <DateInput name="a1Till" label="A1 bis" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        name="a1Notes"
                        label="A1 Bemerkungen"
                        rows={1}
                        rowsMax={6}
                        multiline
                        fullWidth
                    />
                </Grid>
                {nurseDeploymentId && (
                    <Grid item xs={6}>
                        <A1DocumentList
                            nurseDeploymentId={nurseDeploymentId}
                            onChange={(list) => setDeleteA1DocList(list)}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box mt={5} mb={1}>
                        <TitleBar>Notizen</TitleBar>
                    </Box>
                    <TextInput
                        name="notes"
                        label="Notizen"
                        rows={4}
                        rowsMax={10}
                        multiline
                        fullWidth
                    />
                </Grid>
            </Grid>
            <SubmitButton container={submitRef} />
        </Form>
    );
};

NurseDeploymentForm.propTypes = {
    customerId: IdPropType,
    careNeedId: IdPropType,
    nurseDeploymentId: IdPropType,
    copy: PropTypes.bool,
    onDone: PropTypes.func.isRequired,
    submitRef: RefPropType,
};

NurseDeploymentForm.defaultProps = {
    customerId: null,
    careNeedId: null,
    nurseDeploymentId: null,
    copy: false,
    submitRef: null,
};

export default NurseDeploymentForm;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { ContactPropType } from '../propTypes';

const ContactType = ({ contact, primary, secondary }) => (
    <>
        {primary && (
            <>
                {contact.isPatient && <Typography variant="subtitle2">Patient</Typography>}
                {contact.isPrimaryContact && (
                    <Typography variant="subtitle2">Hauptkontakt</Typography>
                )}
                <Typography variant="body2">
                    {contact.type === 'other' ? contact.typeDetail : contact.type}
                </Typography>
            </>
        )}
        {secondary && (
            <Typography variant="body2">
                {contact.isEmergencyContact && (
                    <>
                        Notfallkontakt
                        <br />
                    </>
                )}
                {contact.sendNurseSuggestions && (
                    <>
                        Erhält BK-Vorschläge
                        <br />
                    </>
                )}
                {contact.isContractingPartner && (
                    <>
                        Vertragspartner (Rechnungsempfänger)
                        <br />
                    </>
                )}
                {contact.isInvoiceAddress && (
                    <>
                        Rechnungsversandadresse
                        <br />
                    </>
                )}
            </Typography>
        )}
    </>
);

ContactType.propTypes = {
    contact: ContactPropType.isRequired,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
};

ContactType.defaultProps = {
    primary: false,
    secondary: false,
};

export default ContactType;

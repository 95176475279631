import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import LoadingBox from '../../loading/components/LoadingBox';
import Location from './Location';
import { useCustomerPatientContacts } from '../../customers/hooks';
import { IdPropType } from '../../../proptypes/basic';
import { indexLocations } from '../locationsSlice';

const LocationSuggestion = ({ customerId, zip, label, fallback }) => {
    const [contact] = useCustomerPatientContacts(customerId, true);
    const [initialized, setInitialized] = useState(false);
    const [locationId, setLocationId] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const searchZip = (contact && contact.zip) || zip;

    useEffect(() => {
        if (searchZip) {
            setLoading(true);
            dispatch(indexLocations({ zipCode: searchZip }, null, 'locationSuggestion'))
                .then(({ data }) => {
                    if (data.length > 0) {
                        setLocationId(data[0].id);
                    } else {
                        setLocationId(null);
                    }
                })
                .finally(() => {
                    setInitialized(true);
                    setLoading(false);
                });
        }
    }, [searchZip, dispatch, setLoading, setLocationId, setInitialized]);

    return (
        <>
            {label && (
                <Typography variant="caption" color="textSecondary">
                    {label}
                </Typography>
            )}
            <LoadingBox loading={!initialized || loading} variant="circular" size="small">
                {locationId ? (
                    <Location locationId={locationId} />
                ) : (
                    <Typography variant="inherit">{fallback || 'Kein Vorschlag'}</Typography>
                )}
            </LoadingBox>
        </>
    );
};

LocationSuggestion.propTypes = {
    customerId: IdPropType,
    label: PropTypes.string,
    fallback: PropTypes.string,
    zip: PropTypes.string,
};

LocationSuggestion.defaultProps = {
    customerId: null,
    label: null,
    fallback: null,
    zip: null,
};

export default LocationSuggestion;

import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import { IdPropType } from '../../../proptypes/basic';
import TitleBar from '../../layout/components/TitleBar';
import { formatDateTime } from '../../../helpers/tools';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { PROTOCOL_RESOURCE, CUSTOMER_PROTOCOL_RESOURCE } from '../../api/resources';
import { selectListTotal } from '../../lists/selectors';
import Employee from '../../employees/components/Employee';
import Button from '../../buttons/components/Button';
import { useDialog } from '../../dialogs/components/DialogContext';

import Note from '../../notes/components/Note';

import CreateResourceIconButton from '../../table/components/resource/CreateResourceIconButton';
import { useResourceDialog } from '../../dialogs/hooks';
import { useCanDelete } from '../../abilities/hooks';
import { destroyProtocol } from '../protocolsSlice';

const initialOrderBy = [['createdAt', 'desc']];

const columns = [
    {
        key: 'createdAt',
        label: 'Datum',
        component: ({ createdAt }) => (
            <Typography variant="body2" noWrap>
                {formatDateTime(createdAt)} Uhr
            </Typography>
        ),
    },
    {
        key: 'category',
        label: 'Kategorie',
        component: ({ category, data: { categoryOther } }) =>
            categoryOther ? `${category} [${categoryOther}]` : category,
    },
    {
        key: 'note',
        label: 'Notiz',
        component: Note,
    },
    {
        key: 'createdById',
        as: 'employeeId',
        label: 'Mitarbeiter',
        component: Employee,
    },
];

const Protocol = ({ customerId }) => {
    const listId = `customer.${customerId}.protocol`;
    const canDeleteProtocol = useCanDelete(CUSTOMER_PROTOCOL_RESOURCE);
    const dispatch = useDispatch();

    const count = useSelector((state) => selectListTotal(state, listId));
    const { openProtocolDialog, openReminderDialog } = useDialog();

    const fetchParams = useMemo(() => ({ customerId, property: 'note' }), [customerId]);

    const handleCreate = useResourceDialog(PROTOCOL_RESOURCE, { customerId });

    const handleEdit = useCallback(
        (protocolId) => {
            openProtocolDialog({ customerId, protocolId });
        },
        [openProtocolDialog, customerId]
    );

    const handleDelete = useCallback(
        (protocolId) => {
            dispatch(destroyProtocol({ id: protocolId }));
        },
        [dispatch]
    );

    const handleDeleteDisabled = () => {
        return false;
    };

    const handleReminder = useCallback(
        (protocol) => {
            openReminderDialog({
                customerId,
                prefill: { note: protocol.note },
            });
        },
        [customerId, openReminderDialog]
    );

    const actions = useMemo(
        () => [
            {
                key: 'reminder',
                action: ({ data }) => (
                    <Button startIcon={<AddAlertIcon />} onClick={() => handleReminder(data)}>
                        Reminder
                    </Button>
                ),
            },
        ],
        [handleReminder]
    );

    return (
        <>
            <TitleBar
                aside={
                    <CreateResourceIconButton
                        listId={listId}
                        resource={PROTOCOL_RESOURCE}
                        onClick={handleCreate}
                        size="small"
                    />
                }
            >
                {count || ''} Protokolleinträge
            </TitleBar>

            <ResourceTable
                resource={PROTOCOL_RESOURCE}
                listId={listId}
                onEdit={handleEdit}
                onDelete={canDeleteProtocol ? handleDelete : null}
                columns={columns}
                initialOrderBy={initialOrderBy}
                actions={actions}
                fetchParams={fetchParams}
                deleteDisabled={handleDeleteDisabled}
                autoload
            />
        </>
    );
};

Protocol.propTypes = {
    customerId: IdPropType,
};

Protocol.defaultProps = {
    customerId: null,
};

export default Protocol;

import React from 'react';
import * as PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import MailView from 'Modules/mails/components/MailView';

const NotesPopOver = ({ anchorEl, onClose, open, mailId }) => {
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <MailView mailId={mailId} />
        </Popover>
    );
};

NotesPopOver.propTypes = {
    anchorEl: PropTypes.shape({}),
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    mailId: PropTypes.number,
};

NotesPopOver.defaultProps = {
    anchorEl: null,
    open: false,
    mailId: 0,
};

export default NotesPopOver;

import * as Yup from 'yup';
import { phoneNumberSchema } from '../phonenumbers/schema';

const customerSchema = Yup.object().shape({
    gdprAccepted: Yup.string()
        .oneOf(['gdprPatientAcceptedDate', 'gdprCarerAcceptedDate', 'gdprEssentialAcceptedDate'])
        .required('Pflichtfeld'),
    gdprAdsAcceptedDate: Yup.boolean().required('Pflichtfeld'),
    gdprPatientDataAcceptedDate: Yup.boolean().when('gdprAccepted', {
        is: 'gdprPatientAcceptedDate',
        then: Yup.boolean()
            .required('Pflichtfeld')
            .oneOf(
                [true],
                'Wenn Sie der Verarbeitung und Datenübermittlung nicht zustimmen, können wir für Sie nicht tätig werden. Wir bitten um Ihr Verständnis.'
            ),
    }),
    gdprPatientTransferAcceptedDate: Yup.boolean().when('gdprAccepted', {
        is: 'gdprPatientAcceptedDate',
        then: Yup.boolean()
            .required('Pflichtfeld')
            .oneOf(
                [true],
                'Wenn Sie der Verarbeitung und Datenübermittlung nicht zustimmen, können wir für Sie nicht tätig werden. Wir bitten um Ihr Verständnis.'
            ),
    }),
    gdprCarerDataAcceptedDate: Yup.boolean().when('gdprAccepted', {
        is: 'gdprCarerAcceptedDate',
        then: Yup.boolean()
            .required('Pflichtfeld')
            .oneOf(
                [true],
                'Wenn Sie der Verarbeitung und Datenübermittlung nicht zustimmen, können wir für Sie nicht tätig werden. Wir bitten um Ihr Verständnis.'
            ),
    }),
    gdprCarerTransferAcceptedDate: Yup.boolean().when('gdprAccepted', {
        is: 'gdprCarerAcceptedDate',
        then: Yup.boolean()
            .required('Pflichtfeld')
            .oneOf(
                [true],
                'Wenn Sie der Verarbeitung und Datenübermittlung nicht zustimmen, können wir für Sie nicht tätig werden. Wir bitten um Ihr Verständnis.'
            ),
    }),
    gdprEssentialTransferAcceptedDate: Yup.boolean().when('gdprAccepted', {
        is: 'gdprEssentialAcceptedDate',
        then: Yup.boolean()
            .required('Pflichtfeld')
            .oneOf(
                [true],
                'Wenn Sie der Verarbeitung und Datenübermittlung nicht zustimmen, können wir für Sie nicht tätig werden. Wir bitten um Ihr Verständnis.'
            ),
    }),
    contactedThrough: Yup.array()
        .of(Yup.string())
        .min(1, 'Mindestens 1 auswählen')
        .required('Pflichtfeld'),
    recommendedBy: Yup.string(),
    recommendedByOther: Yup.string(),
    contactedThroughOther: Yup.string(),
});

const mainContactSchema = Yup.object().shape({
    salutation: Yup.string().nullable().required('Pflichtfeld'),
    title: Yup.string(),
    firstname: Yup.string().required('Pflichtfeld'),
    lastname: Yup.string().required('Pflichtfeld'),
    address: Yup.string().required('Pflichtfeld'),
    zip: Yup.string().required('Pflichtfeld'),
    // .min(5, 'PLZ muss mind. 5 Ziffern lang sein')
    // .max(5, 'PLZ darf max. 5 Ziffern lang sein')
    // .matches(/^\d+$/, 'Die PLZ darf nur Zahlen enthalten')
    type: Yup.string(),
    typeDetail: Yup.string(),
    city: Yup.string().required('Pflichtfeld'),
    email: Yup.string(),
    phoneNumbers: Yup.array().of(phoneNumberSchema),
    bornAt: Yup.date().max(new Date(), 'Muss in der Vergangenheit liegen').nullable(),
});

const patientSchema = Yup.object().shape({
    salutation: Yup.string().nullable().required('Pflichtfeld'),
    title: Yup.string(),
    firstname: Yup.string().required('Pflichtfeld'),
    lastname: Yup.string().required('Pflichtfeld'),
    address: Yup.string().required('Pflichtfeld'),
    zip: Yup.string().required('Pflichtfeld'),
    // .min(5, 'PLZ muss mind. 5 Ziffern lang sein')
    // .max(5, 'PLZ darf max. 5 Ziffern lang sein')
    // .matches(/^\d+$/, 'Die PLZ darf nur Zahlen enthalten')
    city: Yup.string().required('Pflichtfeld'),
    email: Yup.string(),
    phoneNumbers: Yup.array().of(phoneNumberSchema),
    bornAt: Yup.date()
        .max(new Date(), 'Muss in der Vergangenheit liegen')
        .nullable()
        .required('Pflichtfeld'),
});

export const questionnaireSchema = Yup.object().shape({
    height: Yup.string()
        .matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden')
        .required('Pflichtfeld'),
    weight: Yup.string()
        .matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden')
        .required('Pflichtfeld'),
    insuranceCompany: Yup.string(),
    nursingLevel: Yup.string().required('Pflichtfeld'),
    upgradeRequested: Yup.boolean().required('Pflichtfeld'),
    powerOfAttorneyExists: Yup.boolean(),
    patientDecreeExists: Yup.boolean(),
    locationOfDocuments: Yup.string(),
    questionnaireData: Yup.object().shape({
        maritalStatus: Yup.string().required('Pflichtfeld'),
        denomination: Yup.object()
            .shape({ value: Yup.string().required('Pflichtfeld'), detail: Yup.string() })
            .required('Pflichtfeld'),
        smoker: Yup.boolean().nullable().required('Pflichtfeld'),
        dementia: Yup.boolean().nullable().required('Pflichtfeld'),
        mainReasonOld: Yup.boolean().required('Pflichtfeld'),
        mainReasonForCare: Yup.string().when('mainReasonOld', {
            is: false,
            then: Yup.string().required('Pflichtfeld'),
        }),
        amputations: Yup.string(),
        paralyses: Yup.string(),
        musculoskeletal: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        neurological: Yup.object().shape({
            value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        }),
        cardiovascular: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        gastroduodenal: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        respiratory: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        otherIlnesses: Yup.array().of(Yup.string()),
        currentProblems: Yup.array().of(Yup.string()),
        since: Yup.string(),
        contageous: Yup.object().shape({
            value: Yup.boolean().nullable().required('Pflichtfeld'),
            detail: Yup.string().when('value', {
                is: true,
                then: Yup.string().required('Pflichtfeld'),
            }),
        }),
        transferDocumentsAttached: Yup.boolean().required('Pflichtfeld'),

        sleep: Yup.object().shape({
            impairments: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            medication: Yup.object().shape({
                details: Yup.string(),
            }),
            from: Yup.string(),
            to: Yup.string(),
            interruptions: Yup.string().required('Pflichtfeld'),
            assistance: Yup.object().shape({
                needed: Yup.boolean().nullable().required('Pflichtfeld'),
                frequency: Yup.string().when('needed', {
                    is: true,
                    then: Yup.string().required('Pflichtfeld'),
                }),
                reason: Yup.string().when('needed', {
                    is: true,
                    then: Yup.string().required('Pflichtfeld'),
                }),
            }),
        }),
        mobility: Yup.object().shape({
            impairments: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        }),

        transfer: Yup.object().shape({
            assistance: Yup.object().shape({
                needed: Yup.string().nullable().required('Pflichtfeld'),
                frequency: Yup.string(),
            }),
            dependence: Yup.string(),
        }),

        bedPositioning: Yup.object().shape({
            needsHelp: Yup.string().nullable().required('Pflichtfeld'),
            helpFrequency: Yup.string(),
            dependence: Yup.string(),
        }),

        hygiene: Yup.object().shape({
            basic: Yup.string(),
            faceAndMouth: Yup.string(),
            torso: Yup.string(),
            arms: Yup.string(),
            back: Yup.string(),
            privateParts: Yup.string(),
            hairWash: Yup.string(),
            hairComb: Yup.string(),
            shave: Yup.string(),
            skin: Yup.string(),
            nails: Yup.string(),
            bath: Yup.string(),
            washAtBasin: Yup.string(),
            choosingClothes: Yup.string(),
            changingClothes: Yup.string(),
            onlyInBed: Yup.boolean().required('Pflichtfeld'),
            details: Yup.string(),
        }),

        food: Yup.object().shape({
            diet: Yup.object().shape({
                value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                special: Yup.string(),
            }),
            allergies: Yup.object().shape({
                details: Yup.string(),
            }),
            preparationHelp: Yup.object().shape({
                details: Yup.string(),
            }),
            intakeHelp: Yup.object().shape({
                details: Yup.string(),
            }),
            nutritionalStatus: Yup.string(),
            fluidIntake: Yup.object().shape({ value: Yup.string(), mlPerDay: Yup.string() }),
            notes: Yup.string(),
        }),

        excretion: Yup.object().shape({
            toilet: Yup.string().required('Pflichtfeld'),
            incontinenceUrinary: Yup.string().required('Pflichtfeld'),
            incontinenceFecal: Yup.string().required('Pflichtfeld'),
            other: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            details: Yup.string(),
            incontinenceCare: Yup.object().when(['incontinenceUrinary', 'incontinenceFecal'], {
                is: (incontinenceUrinary, incontinenceFecal) =>
                    ['sometimes', 'always'].includes(incontinenceUrinary) ||
                    ['sometimes', 'always'].includes(incontinenceFecal),
                then: Yup.object().shape({
                    dependence: Yup.string().required('Pflichtfeld'),
                }),
            }),
        }),

        aid: Yup.object().shape({
            aidsInHousehold: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            orthotic: Yup.object().shape({
                detail: Yup.string(),
            }),
            prosthesis: Yup.object().shape({
                detail: Yup.string(),
            }),
            notes: Yup.string(),
        }),

        breathing: Yup.object().shape({
            impediments: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            reasonForCPAP: Yup.string(),
        }),
        safety: Yup.object().shape({
            homeEmergencyCallServiceExists: Yup.boolean(),
            stateOfConciousness: Yup.string(),
            basic: Yup.string(),
            orientationProblems: Yup.object().shape({
                temporal: Yup.string(),
                positional: Yup.string(),
                personal: Yup.string(),
                situational: Yup.string(),
            }),
            wanderingTendency: Yup.object().shape({
                value: Yup.boolean().required('Pflichtfeld'),
                details: Yup.string(),
            }),
            medicationIntake: Yup.string(),
        }),
        occupation: Yup.object().shape({
            dailyRoutine: Yup.object().shape({
                wake: Yup.boolean().required('Pflichtfeld'),
                wakeTime: Yup.string(),
                letSleep: Yup.boolean().required('Pflichtfeld'),
                independent: Yup.boolean().required('Pflichtfeld'),
                morning: Yup.string(),
                forenoon: Yup.string(),
                noon: Yup.string(),
                afternoon: Yup.string(),
                evening: Yup.string(),
            }),
            dependence: Yup.string(),
            formerJob: Yup.string(),
            hobbies: Yup.string(),
            outOfHomeAppointment: Yup.object().shape({
                value: Yup.boolean().required('Pflichtfeld'),
                frequency: Yup.string(),
            }),
            currentTherapies: Yup.object().shape({
                value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                other: Yup.string(),
            }),
        }),
        communication: Yup.object().shape({
            language: Yup.object().shape({
                value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                other: Yup.string(),
                uninhibited: Yup.boolean().required('Pflichtfeld'),
            }),
            impediments: Yup.object().shape({
                speech: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                hearing: Yup.string().nullable(),
                visual: Yup.string().nullable(),
                strengthOfVision: Yup.string(),
            }),
        }),
        mentalState: Yup.object().shape({
            value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            other: Yup.string(),
        }),
        pain: Yup.object().shape({
            level: Yup.object().shape({ resting: Yup.string(), strained: Yup.string() }),
            reason: Yup.string(),
            therapyVia: Yup.string(),
            notes: Yup.string(),
        }),

        nurseRequestParameters: Yup.object().shape({
            gender: Yup.number(),
            ageRange: Yup.object().shape({
                ranges: Yup.array().of(Yup.string()).nullable(),
            }),
            driversLicense: Yup.string(),
            nurseData: Yup.object().shape({
                car: Yup.object().shape({
                    transmission: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                    model: Yup.string(),
                }),
                qualifications: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                customerNotes: Yup.string(),
            }),
            languageSkill: Yup.object().shape({
                german: Yup.array().of(Yup.string()).nullable(),
            }),
            smoker: Yup.string(),

            householdData: Yup.object().shape({
                noOfPeople: Yup.string(),
                willBePresent: Yup.boolean().required('Pflichtfeld'),
                tasksForContacts: Yup.string(),
                additionalPeople: Yup.string().required('Pflichtfeld'),
                tasks: Yup.object().shape({
                    wishes: Yup.array().of(Yup.string()),
                    other: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                    accompanyDoctorVisits: Yup.boolean().required('Pflichtfeld'),
                }),
                gardening: Yup.object().shape({
                    value: Yup.boolean().required('Pflichtfeld'),
                    details: Yup.string(),
                }),
                petCare: Yup.object().shape({
                    value: Yup.boolean().required('Pflichtfeld'),
                    details: Yup.string(),
                }),
                additionalHomeHelp: Yup.object().shape({
                    details: Yup.string(),
                }),
                residentialSituation: Yup.array()
                    .of(Yup.string())
                    .min(1, 'Mindestens 1 auswählen')
                    .required('Pflichtfeld'),
                shoppingDistanceOnFoot: Yup.string().matches(
                    /^\d+$/,
                    'Bitte nur positive Zahlen verwenden'
                ),
                cleaningSupplies: Yup.string().required('Pflichtfeld'),
                budget: Yup.string().required('Pflichtfeld'),
                noOfRooms: Yup.string().matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden'),
                notes: Yup.string(),
                squareMeters: Yup.string().matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden'),
                nurseAccommodation: Yup.object().shape({
                    room: Yup.string().required('Pflichtfeld'),
                    roomType: Yup.string().required('Pflichtfeld'),
                    furniture: Yup.string().required('Pflichtfeld'),
                    roomLivingStandards: Yup.string().required('Pflichtfeld'),
                    kitchen: Yup.string().required('Pflichtfeld'),
                    kitchenType: Yup.string().required('Pflichtfeld'),
                    bathroom: Yup.string().required('Pflichtfeld'),
                    bathroomType: Yup.string().required('Pflichtfeld'),
                    washing: Yup.string().required('Pflichtfeld'),
                    wifi: Yup.string().required('Pflichtfeld'),
                    transport: Yup.string().required('Pflichtfeld'),
                    transportDetails: Yup.string().when('transport', {
                        is: 'provided',
                        then: Yup.string().required('Pflichtfeld'),
                    }),
                    // features: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                    notes: Yup.string(),
                }),
            }),
        }),
    }),
    dementiaData: Yup.object().shape({
        since: Yup.string(),
        diagnosedByProfessional: Yup.boolean().required('Pflichtfeld'),
        type: Yup.string(),
        currentTherapy: Yup.string(),
        restrictions: Yup.object().shape({
            recognizingPeople: Yup.string(),
            positionalOrientation: Yup.string(),
            temporalOrientation: Yup.string(),
            rememberingEvents: Yup.string(),
            controlOfMundaneTasks: Yup.string(),
            mundaneDecisionMaking: Yup.string(),
            comprehension: Yup.string(),
            riskAssessment: Yup.string(),
            communicatingElementaryNeeds: Yup.string(),
            understandingAndFollowingRequests: Yup.string(),
            conversationParticipation: Yup.string(),
        }),
        problems: Yup.object().shape({
            motorProblems: Yup.string(),
            nightlynrest: Yup.string(), // I guess these typos will be our companions forever :>
            hidingCollectingMisplacingObjectsOrMoney: Yup.string(),
            damagingObjects: Yup.string(),
            physicallyAggressiveBehaviour: Yup.string(),
            verballyAggressiveBehaviour: Yup.string(),
            otherRelevantVocalizations: Yup.string(),
            defenseOfSupportiveMeasures: Yup.string(),
            fears: Yup.string(),
            listlessness: Yup.string(),
            sociallyInadequateBehaviour: Yup.string(),
            addictions: Yup.string(),
            sexualPhysicalOvertures: Yup.string(),
            inappropriateChangingOfClothes: Yup.string(),
        }),
        wanderingTendency: Yup.string(),
        securityHouseApartment: Yup.object().shape({
            value: Yup.boolean().required('Pflichtfeld'),
            detail: Yup.string(),
        }),
        securityEnvironment: Yup.object().shape({
            value: Yup.boolean().required('Pflichtfeld'),
            detail: Yup.string(),
        }),
        goodToGuideToHygiene: Yup.string(),
        goodToGuideToEverydayLife: Yup.string(),
        ritualOrMedicationForRestlessness: Yup.object().shape({
            value: Yup.string(),
            detail: Yup.string(),
        }),
        behaviourWhenRejected: Yup.string(),
        listensTo: Yup.object().shape({ value: Yup.string(), detail: Yup.string() }),
        dailyStructureAndCooperationNotes: Yup.string(),
    }),
    anamnesisData: Yup.object().shape({
        mainReasonForCare: Yup.string(),
        mainReasonForCareIdentical: Yup.boolean().required('Pflichtfeld'),

        sleep: Yup.object().shape({ assistance: Yup.string() }),
        mobility: Yup.object().shape({
            confinedToBed: Yup.string(),
            fallEndangered: Yup.string(),
        }),
        hygiene: Yup.object().shape({ independence: Yup.string() }),
        careRequirements: Yup.object().shape({
            confirmed: Yup.boolean().nullable(),
            details: Yup.string(),
        }),

        safety: Yup.object().shape({
            familiar: Yup.boolean().required('Pflichtfeld'),
            cognitiveLimitations: Yup.boolean().required('Pflichtfeld'),
            unfamiliar: Yup.boolean().required('Pflichtfeld'),
        }),
        occupation: Yup.object().shape({ independence: Yup.string() }),
        therapy: Yup.string(),
        consciousness: Yup.object().shape({
            confirmed: Yup.boolean().nullable(),
            details: Yup.string(),
        }),
        communication: Yup.object().shape({
            unlimited: Yup.boolean().required('Pflichtfeld'),
            current: Yup.boolean().required('Pflichtfeld'),
            limited: Yup.boolean().required('Pflichtfeld'),
            basic: Yup.boolean().required('Pflichtfeld'),
            none: Yup.boolean().required('Pflichtfeld'),
        }),

        network: Yup.object().shape({
            discrepancies: Yup.object().shape({
                value: Yup.boolean().required('Pflichtfeld'),
                detail: Yup.string(),
            }),
            description: Yup.string(),
        }),

        household: Yup.object().shape({
            independence: Yup.string(),
        }),

        meta: Yup.object().shape({
            summary: Yup.string(),
            result: Yup.array().of(Yup.string()).nullable(),
            employee: Yup.string(),
            employeeRegisteredNurseId: Yup.string(),
            anamnesis: Yup.object().shape({
                conductedWithName: Yup.string(),
                conductedWithOther: Yup.string(),
            }),
        }),
    }),
});

export const questionnaireAnamneseSchema = Yup.object().shape({
    height: Yup.string()
        .matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden')
        .required('Pflichtfeld'),
    weight: Yup.string()
        .matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden')
        .required('Pflichtfeld'),
    insuranceCompany: Yup.string(),
    nursingLevel: Yup.string().required('Pflichtfeld'),
    upgradeRequested: Yup.boolean().required('Pflichtfeld'),
    powerOfAttorneyExists: Yup.boolean(),
    patientDecreeExists: Yup.boolean(),
    locationOfDocuments: Yup.string(),
    questionnaireData: Yup.object().shape({
        maritalStatus: Yup.string().required('Pflichtfeld'),
        denomination: Yup.object()
            .shape({ value: Yup.string().required('Pflichtfeld'), detail: Yup.string() })
            .required('Pflichtfeld'),
        smoker: Yup.boolean().nullable().required('Pflichtfeld'),
        dementia: Yup.boolean().nullable().required('Pflichtfeld'),
        mainReasonOld: Yup.boolean().required('Pflichtfeld'),
        mainReasonForCare: Yup.string().when('mainReasonOld', {
            is: false,
            then: Yup.string().required('Pflichtfeld'),
        }),
        amputations: Yup.string(),
        paralyses: Yup.string(),
        musculoskeletal: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        neurological: Yup.object().shape({
            value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        }),
        cardiovascular: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        gastroduodenal: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        respiratory: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        otherIlnesses: Yup.array().of(Yup.string()),
        currentProblems: Yup.array().of(Yup.string()),
        since: Yup.string(),
        contageous: Yup.object().shape({
            value: Yup.boolean().nullable().required('Pflichtfeld'),
            detail: Yup.string().when('value', {
                is: true,
                then: Yup.string().required('Pflichtfeld'),
            }),
        }),
        transferDocumentsAttached: Yup.boolean().required('Pflichtfeld'),

        sleep: Yup.object().shape({
            impairments: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            medication: Yup.object().shape({
                details: Yup.string(),
            }),
            from: Yup.string(),
            to: Yup.string(),
            interruptions: Yup.string().required('Pflichtfeld'),
            assistance: Yup.object().shape({
                needed: Yup.boolean().nullable().required('Pflichtfeld'),
                frequency: Yup.string().when('needed', {
                    is: true,
                    then: Yup.string().required('Pflichtfeld'),
                }),
                reason: Yup.string().when('needed', {
                    is: true,
                    then: Yup.string().required('Pflichtfeld'),
                }),
            }),
        }),
        mobility: Yup.object().shape({
            impairments: Yup.array().of(Yup.string()).required('Pflichtfeld'),
        }),

        transfer: Yup.object().shape({
            assistance: Yup.object().shape({
                needed: Yup.string().nullable().required('Pflichtfeld'),
                frequency: Yup.string(),
            }),
            dependence: Yup.string(),
        }),

        bedPositioning: Yup.object().shape({
            needsHelp: Yup.string().nullable().required('Pflichtfeld'),
            helpFrequency: Yup.string(),
            dependence: Yup.string(),
        }),

        hygiene: Yup.object().shape({
            basic: Yup.string(),
            faceAndMouth: Yup.string(),
            torso: Yup.string(),
            arms: Yup.string(),
            back: Yup.string(),
            privateParts: Yup.string(),
            hairWash: Yup.string(),
            hairComb: Yup.string(),
            shave: Yup.string(),
            skin: Yup.string(),
            nails: Yup.string(),
            bath: Yup.string(),
            washAtBasin: Yup.string(),
            choosingClothes: Yup.string(),
            changingClothes: Yup.string(),
            onlyInBed: Yup.boolean().required('Pflichtfeld'),
            details: Yup.string(),
        }),

        food: Yup.object().shape({
            diet: Yup.object().shape({
                value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                special: Yup.string(),
            }),
            allergies: Yup.object().shape({
                details: Yup.string(),
            }),
            preparationHelp: Yup.object().shape({
                details: Yup.string(),
            }),
            intakeHelp: Yup.object().shape({
                details: Yup.string(),
            }),
            nutritionalStatus: Yup.string(),
            fluidIntake: Yup.object().shape({ value: Yup.string(), mlPerDay: Yup.string() }),
            notes: Yup.string(),
        }),

        excretion: Yup.object().shape({
            toilet: Yup.string().required('Pflichtfeld'),
            incontinenceUrinary: Yup.string().required('Pflichtfeld'),
            incontinenceFecal: Yup.string().required('Pflichtfeld'),
            other: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            details: Yup.string(),
            incontinenceCare: Yup.object().when(['incontinenceUrinary', 'incontinenceFecal'], {
                is: (incontinenceUrinary, incontinenceFecal) =>
                    ['sometimes', 'always'].includes(incontinenceUrinary) ||
                    ['sometimes', 'always'].includes(incontinenceFecal),
                then: Yup.object().shape({
                    dependence: Yup.string().required('Pflichtfeld'),
                }),
            }),
        }),

        aid: Yup.object().shape({
            aidsInHousehold: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            orthotic: Yup.object().shape({
                detail: Yup.string(),
            }),
            prosthesis: Yup.object().shape({
                detail: Yup.string(),
            }),
            notes: Yup.string(),
        }),

        breathing: Yup.object().shape({
            impediments: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            reasonForCPAP: Yup.string(),
        }),
        safety: Yup.object().shape({
            homeEmergencyCallServiceExists: Yup.boolean(),
            stateOfConciousness: Yup.string(),
            basic: Yup.string(),
            orientationProblems: Yup.object().shape({
                temporal: Yup.string(),
                positional: Yup.string(),
                personal: Yup.string(),
                situational: Yup.string(),
            }),
            wanderingTendency: Yup.object().shape({
                value: Yup.boolean().required('Pflichtfeld'),
                details: Yup.string(),
            }),
            medicationIntake: Yup.string(),
        }),
        occupation: Yup.object().shape({
            dailyRoutine: Yup.object().shape({
                wake: Yup.boolean().required('Pflichtfeld'),
                wakeTime: Yup.string(),
                letSleep: Yup.boolean().required('Pflichtfeld'),
                independent: Yup.boolean().required('Pflichtfeld'),
                morning: Yup.string(),
                forenoon: Yup.string(),
                noon: Yup.string(),
                afternoon: Yup.string(),
                evening: Yup.string(),
            }),
            dependence: Yup.string(),
            formerJob: Yup.string(),
            hobbies: Yup.string(),
            outOfHomeAppointment: Yup.object().shape({
                value: Yup.boolean().required('Pflichtfeld'),
                frequency: Yup.string(),
            }),
            currentTherapies: Yup.object().shape({
                value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                other: Yup.string(),
            }),
        }),
        communication: Yup.object().shape({
            language: Yup.object().shape({
                value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                other: Yup.string(),
                uninhibited: Yup.boolean().required('Pflichtfeld'),
            }),
            impediments: Yup.object().shape({
                speech: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                hearing: Yup.string().nullable(),
                visual: Yup.string().nullable(),
                strengthOfVision: Yup.string(),
            }),
        }),
        mentalState: Yup.object().shape({
            value: Yup.array().of(Yup.string()).required('Pflichtfeld'),
            other: Yup.string(),
        }),
        pain: Yup.object().shape({
            level: Yup.object().shape({ resting: Yup.string(), strained: Yup.string() }),
            reason: Yup.string(),
            therapyVia: Yup.string(),
            notes: Yup.string(),
        }),

        nurseRequestParameters: Yup.object().shape({
            gender: Yup.number(),
            ageRange: Yup.object().shape({
                ranges: Yup.array().of(Yup.string()).nullable(),
            }),
            driversLicense: Yup.string(),
            nurseData: Yup.object().shape({
                car: Yup.object().shape({
                    transmission: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                    model: Yup.string(),
                }),
                qualifications: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                customerNotes: Yup.string(),
            }),
            languageSkill: Yup.object().shape({
                german: Yup.array().of(Yup.string()).nullable(),
            }),
            smoker: Yup.string(),

            householdData: Yup.object().shape({
                noOfPeople: Yup.string(),
                willBePresent: Yup.boolean().required('Pflichtfeld'),
                tasksForContacts: Yup.string(),
                additionalPeople: Yup.string().required('Pflichtfeld'),
                tasks: Yup.object().shape({
                    wishes: Yup.array().of(Yup.string()),
                    other: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                    accompanyDoctorVisits: Yup.boolean().required('Pflichtfeld'),
                }),
                gardening: Yup.object().shape({
                    value: Yup.boolean().required('Pflichtfeld'),
                    details: Yup.string(),
                }),
                petCare: Yup.object().shape({
                    value: Yup.boolean().required('Pflichtfeld'),
                    details: Yup.string(),
                }),
                additionalHomeHelp: Yup.object().shape({
                    details: Yup.string(),
                }),
                residentialSituation: Yup.array()
                    .of(Yup.string())
                    .min(1, 'Mindestens 1 auswählen')
                    .required('Pflichtfeld'),
                shoppingDistanceOnFoot: Yup.string().matches(
                    /^\d+$/,
                    'Bitte nur positive Zahlen verwenden'
                ),
                cleaningSupplies: Yup.string().required('Pflichtfeld'),
                budget: Yup.string().required('Pflichtfeld'),
                noOfRooms: Yup.string().matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden'),
                notes: Yup.string(),
                squareMeters: Yup.string().matches(/^\d+$/, 'Bitte nur positive Zahlen verwenden'),
                nurseAccommodation: Yup.object().shape({
                    room: Yup.string().required('Pflichtfeld'),
                    roomType: Yup.string().required('Pflichtfeld'),
                    furniture: Yup.string().required('Pflichtfeld'),
                    roomLivingStandards: Yup.string().required('Pflichtfeld'),
                    kitchen: Yup.string().required('Pflichtfeld'),
                    kitchenType: Yup.string().required('Pflichtfeld'),
                    bathroom: Yup.string().required('Pflichtfeld'),
                    bathroomType: Yup.string().required('Pflichtfeld'),
                    washing: Yup.string().required('Pflichtfeld'),
                    wifi: Yup.string().required('Pflichtfeld'),
                    transport: Yup.string().required('Pflichtfeld'),
                    transportDetails: Yup.string().when('transport', {
                        is: 'provided',
                        then: Yup.string().required('Pflichtfeld'),
                    }),
                    // features: Yup.array().of(Yup.string()).required('Pflichtfeld'),
                    notes: Yup.string(),
                }),
            }),
        }),
    }),
    dementiaData: Yup.object().shape({
        since: Yup.string(),
        diagnosedByProfessional: Yup.boolean().required('Pflichtfeld'),
        type: Yup.string(),
        currentTherapy: Yup.string(),
        restrictions: Yup.object().shape({
            recognizingPeople: Yup.string(),
            positionalOrientation: Yup.string(),
            temporalOrientation: Yup.string(),
            rememberingEvents: Yup.string(),
            controlOfMundaneTasks: Yup.string(),
            mundaneDecisionMaking: Yup.string(),
            comprehension: Yup.string(),
            riskAssessment: Yup.string(),
            communicatingElementaryNeeds: Yup.string(),
            understandingAndFollowingRequests: Yup.string(),
            conversationParticipation: Yup.string(),
        }),
        problems: Yup.object().shape({
            motorProblems: Yup.string(),
            nightlynrest: Yup.string(), // I guess these typos will be our companions forever :>
            hidingCollectingMisplacingObjectsOrMoney: Yup.string(),
            damagingObjects: Yup.string(),
            physicallyAggressiveBehaviour: Yup.string(),
            verballyAggressiveBehaviour: Yup.string(),
            otherRelevantVocalizations: Yup.string(),
            defenseOfSupportiveMeasures: Yup.string(),
            fears: Yup.string(),
            listlessness: Yup.string(),
            sociallyInadequateBehaviour: Yup.string(),
            addictions: Yup.string(),
            sexualPhysicalOvertures: Yup.string(),
            inappropriateChangingOfClothes: Yup.string(),
        }),
        wanderingTendency: Yup.string(),
        securityHouseApartment: Yup.object().shape({
            value: Yup.boolean().required('Pflichtfeld'),
            detail: Yup.string(),
        }),
        securityEnvironment: Yup.object().shape({
            value: Yup.boolean().required('Pflichtfeld'),
            detail: Yup.string(),
        }),
        goodToGuideToHygiene: Yup.string(),
        goodToGuideToEverydayLife: Yup.string(),
        ritualOrMedicationForRestlessness: Yup.object().shape({
            value: Yup.string(),
            detail: Yup.string(),
        }),
        behaviourWhenRejected: Yup.string(),
        listensTo: Yup.object().shape({ value: Yup.string(), detail: Yup.string() }),
        dailyStructureAndCooperationNotes: Yup.string(),
    }),
    anamnesisData: Yup.object().shape({
        mainReasonForCare: Yup.string(),
        mainReasonForCareIdentical: Yup.boolean().required('Pflichtfeld'),

        sleep: Yup.object().shape({ assistance: Yup.string() }),
        mobility: Yup.object().shape({
            confinedToBed: Yup.string(),
            fallEndangered: Yup.string(),
        }),
        hygiene: Yup.object().shape({ independence: Yup.string() }),
        careRequirements: Yup.object().shape({
            confirmed: Yup.boolean().nullable(),
            details: Yup.string(),
        }),

        safety: Yup.object().shape({
            familiar: Yup.boolean().required('Pflichtfeld'),
            cognitiveLimitations: Yup.boolean().required('Pflichtfeld'),
            unfamiliar: Yup.boolean().required('Pflichtfeld'),
        }),
        occupation: Yup.object().shape({ independence: Yup.string() }),
        therapy: Yup.string(),
        consciousness: Yup.object().shape({
            confirmed: Yup.boolean().nullable(),
            details: Yup.string(),
        }),
        communication: Yup.object().shape({
            unlimited: Yup.boolean().required('Pflichtfeld'),
            current: Yup.boolean().required('Pflichtfeld'),
            limited: Yup.boolean().required('Pflichtfeld'),
            basic: Yup.boolean().required('Pflichtfeld'),
            none: Yup.boolean().required('Pflichtfeld'),
        }),

        network: Yup.object().shape({
            discrepancies: Yup.object().shape({
                value: Yup.boolean().required('Pflichtfeld'),
                detail: Yup.string(),
            }),
            description: Yup.string(),
        }),

        household: Yup.object().shape({
            independence: Yup.string(),
        }),

        meta: Yup.object().shape({
            summary: Yup.string(),
            result: Yup.array().of(Yup.string()).nullable(),
            employee: Yup.string(),
            employeeRegisteredNurseId: Yup.string().required('Pflichtfeld'),
            anamnesis: Yup.object().shape({
                conductedWithName: Yup.string(),
                conductedWithOther: Yup.string(),
            }),
        }),
    }),
});

export const questionnaireWithContactsSchema = Yup.object().shape({
    customer: customerSchema,
    patient: patientSchema,
    questionnaire: questionnaireSchema,
    maincontact: mainContactSchema,
});

export const questionnaireWithContactSchema = Yup.object().shape({
    patient: patientSchema,
    questionnaire: questionnaireSchema,
});

export const questionnaireWithoutContactSchema = Yup.object().shape({
    questionnaire: questionnaireSchema,
});

export const questionnaireWithoutContactAnamneseSchema = Yup.object().shape({
    customer: customerSchema,
    questionnaire: questionnaireAnamneseSchema,
});

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../dialogs/components/Dialog';
import { useCallbackRef } from '../../hooks';
import { IdPropType } from '../../../proptypes/basic';
import LoadingBox from '../../loading/components/LoadingBox';
import DialogFormActions from '../../dialogs/components/DialogFormActions';
import { useQuestionnairesByContactId } from '../questionnairesSlice';
import QuestionnaireForm from './form/QuestionnaireForm';
import SendQuestionnaireButton from '../../buttons/components/specialized/SendQuestionnaireButton';
import QuestionnaireVersionSelect from './form/QuestionnaireVersionSelect';
import { CONTACT_QUESTIONNAIRE_EDIT_RESOURCE } from '../../api/resources';
import { useCanWrite } from '../../abilities/hooks';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfDocumentView from '@pzh/crm/src/Modules/questionnaires/components/pdfDocumentView/PdfDocumentView';
import Summary from './pdfDocumentView/pages/Summary';
import { useCustomer } from '../../customers/customersSlice';
import Button from '../../buttons/components/Button';
import CSVDownloadLink from './csvDownloadLink/csvDownloadLink';

const QuestionnaireDialog = ({ customerId, contactId, onClose, primaryContactId }) => {
    const ref = useCallbackRef();
    const [pdfData, setPdfData] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const [questionnaireId, setQuestionnaireId] = useState(null);
    const [newestQuestionnaireId, setNewestQuestionnaireId] = useState(null);
    const [customer] = useCustomer(customerId);
    const [questionnaires, { loading, initialized }] = useQuestionnairesByContactId(contactId);
    const [showPDF, setShowPDF] = useState(false);
    const [showCSV, setShowCSV] = useState(false);
    const currentQuestionnaire = questionnaires.find(
        (questionnaire) => questionnaire.id === questionnaireId
    );

    const canWriteQuestionareEdit = useCanWrite(CONTACT_QUESTIONNAIRE_EDIT_RESOURCE);

    const handleChange = (name, value) => {
        setQuestionnaireId(value);
    };

    const formRefPdf = useCallback(
        (node) => {
            if (node !== null) {
                setPdfData(node.values);
                setCsvData(node.values);
            }
        },
        [setPdfData,setCsvData]
    );

    useEffect(() => {
        const newest = questionnaires.reduce(
            (carry, questionnaire) =>
                carry && carry.createdAt > questionnaire.createdAt ? carry : questionnaire,
            null
        );
        setQuestionnaireId(newest ? newest.id : null);
        setNewestQuestionnaireId(newest ? newest.id : null);
    }, [questionnaires, setQuestionnaireId]);

    return (
        <Dialog fullWidth maxWidth="md">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Erhebungsbogen</DialogTitle>

            <DialogContent>
                <QuestionnaireVersionSelect
                    value={questionnaireId}
                    contactId={contactId}
                    name="version"
                    onChange={handleChange}
                />
                <LoadingBox loading={loading} initialized={initialized}>
                    <QuestionnaireForm
                        customerId={customerId}
                        patientId={contactId}
                        questionnaireId={questionnaireId}
                        primaryContactId={primaryContactId}
                        disabled={!canWriteQuestionareEdit}
                        onDone={onClose}
                        submitRef={ref}
                        forceDisableCanUpdateCustomer={false}
                        anamneseSchema={true}
                        preventSubmit={questionnaireId !== newestQuestionnaireId}
                        formRef={formRefPdf}
                    />
                </LoadingBox>
            </DialogContent>

            <DialogFormActions ref={ref}>
                <Grid item style={{ marginRight: 'auto', marginLeft: 0 }}>
                    <Box>
                        {!showPDF && (
                            <Button
                                onClick={() => {
                                    setShowPDF((prev) => !prev);
                                    setShowCSV(false);
                                }}
                                variant="outlined"
                            >
                                PDF generieren
                            </Button>
                        )}

                        {!showCSV && (
                            <Button
                                onClick={() => {
                                    setShowCSV((prev) => !prev);
                                    setShowPDF(false);
                                }}
                                variant="outlined"
                            >
                                CSV generieren
                            </Button>
                        )}

                        {showCSV && csvData && (
                            <>
                                <CSVDownloadLink
                                    data={csvData}
                                    fileName={`questionnaire_${customerId}.csv`}
                                />
                            </>
                        )}

                        {showPDF && pdfData && (
                            <>
                                <PDFDownloadLink
                                    document={
                                        <PdfDocumentView
                                            data={pdfData}
                                            customerId={customerId}
                                            questionnaireCreatedDate={
                                                currentQuestionnaire?.createdAt
                                            }
                                        />
                                    }
                                    fileName={`questionnaire_${customerId}.pdf`}
                                >
                                    {({ loading }) =>
                                        loading ? ' Pdf wird geladen...' : ' Pdf herunterladen'
                                    }
                                </PDFDownloadLink>
                                <PDFDownloadLink
                                    document={
                                        <Summary
                                            data={pdfData}
                                            customer={customer}
                                            questionnaireCreatedDate={
                                                currentQuestionnaire?.createdAt
                                            }
                                        />
                                    }
                                    fileName={`questionnaire_summary_${customerId}.pdf`}
                                >
                                    {({ loading }) =>
                                        loading
                                            ? ' Zusammenfassung wird geladen...'
                                            : ' Zusammenfassung herunterladen'
                                    }
                                </PDFDownloadLink>
                            </>
                        )}
                    </Box>
                </Grid>
                <Grid item>
                    <SendQuestionnaireButton
                        name="questionnaire_dialog"
                        contactId={contactId}
                        contactCanUpdate={questionnaires?.length ? false : true}
                        customerId={customerId}
                        primaryContactId={primaryContactId}
                        variant={undefined}
                    />
                </Grid>
                <Grid item />
            </DialogFormActions>
        </Dialog>
    );
};

QuestionnaireDialog.propTypes = {
    customerId: IdPropType.isRequired,
    contactId: IdPropType.isRequired,
    onClose: PropTypes.func.isRequired,
    primaryContactId: IdPropType.number,
};

QuestionnaireDialog.defaultProps = {
    primaryContactId: null,
};

export default QuestionnaireDialog;

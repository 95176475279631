import * as Yup from 'yup';
import { YupId } from '../form/schema';
import { phoneNumberSchema } from '../phonenumbers/schema';

export const interestedCustomerSchema = Yup.object().shape({
    locationId: YupId().required('Pflichtfeld'),
    employeeId: YupId(),
    careStartAt: Yup.date().nullable(),

    receivedAt: Yup.date().nullable(),

    contactedVia: Yup.string(),
    contactedViaOther: Yup.string(),
    contactedThrough: Yup.array().of(Yup.string()),
    contactedThroughOther: Yup.string(),
    recommendedBy: Yup.string(),

    icZipcode: Yup.string(),
    icStart: Yup.string(),
    icPersonsInHouse: Yup.string(),
    icPersonsCare: Yup.string(),
    icExtraRoom: Yup.string(),
    icPatientAgrees: Yup.bool().nullable(),
    icBudget: Yup.string(),
    icBudgetUnclear: Yup.string(),

    consultedAt: Yup.date().nullable(),
    onsiteConsultedAt: Yup.date().nullable(),
    consultedById: YupId(),
    consultedWith: Yup.string(),
    icAcceptsContract: Yup.bool().nullable(),

    gdprPatientAcceptedDate: Yup.date().nullable(),
    gdprPatientDeclinedDate: Yup.date().nullable(),
    gdprPatientDataAcceptedDate: Yup.date().nullable(),
    gdprPatientDataDeclinedDate: Yup.date().nullable(),
    gdprPatientTransferAcceptedDate: Yup.date().nullable(),
    gdprPatientTransferDeclinedDate: Yup.date().nullable(),
    gdprCarerAcceptedDate: Yup.date().nullable(),
    gdprCarerDeclinedDate: Yup.date().nullable(),
    gdprCarerDataAcceptedDate: Yup.date().nullable(),
    gdprCarerDataDeclinedDate: Yup.date().nullable(),
    gdprCarerTransferAcceptedDate: Yup.date().nullable(),
    gdprCarerTransferDeclinedDate: Yup.date().nullable(),
    gdprEssentialAcceptedDate: Yup.date().nullable(),
    gdprEssentialDeclinedDate: Yup.date().nullable(),
    gdprEssentialTransferAcceptedDate: Yup.date().nullable(),
    gdprEssentialTransferDeclinedDate: Yup.date().nullable(),
    gdprAdsAcceptedDate: Yup.date().nullable(),
    gdprAdsDeclinedDate: Yup.date().nullable(),

    notes: Yup.string(),
    reminder: Yup.date().nullable(),
});

export const customerSchema = Yup.object().shape({
    currentType: Yup.object().shape({
        name: Yup.string(),
        details: Yup.string(),
    }),
    primaryContactId: YupId().required('Pflichtfeld'),
    contractingPartnerId: YupId(),
    employeeId: YupId(),
    employeeFormerId: YupId(),
    locationId: YupId().required('Pflichtfeld'),
    assignedLocationAt: Yup.date().nullable(),
    multiplierId: YupId(),
    notes: Yup.string(),
});

export const customerWithContactSchema = Yup.object().shape({
    customer: Yup.object().shape({
        infomaterialSentAt: Yup.bool().required('Pflichtfeld'),
        infomaterialSentVia: Yup.string(),
        icZipcode: Yup.string()
            .when('infomaterialSentVia', {
                is: (infomaterialSentVia) => {
                    // console.log(infomaterialSentVia);
                    return false;
                },
                then: Yup.string(),
                otherwise: Yup.string(),
            })
            .required('Pflichtfeld'),
        locationId: YupId(),
        contactedVia: Yup.string().required('Pflichtfeld'),
        contactedViaOther: Yup.string(),
        contactedThrough: Yup.array()
            .of(Yup.string())
            .min(1, 'Mindestens 1 auswählen')
            .required('Pflichtfeld'),
        contactedThroughOther: Yup.string().when('contactedThrough', {
            is: (contactedThrough) => {
                if (contactedThrough && contactedThrough.includes('other')) {
                    return true;
                }
                return false;
            },
            then: Yup.string().required(
                'Bitte mache hier weitere Anmerkungen zu der Aussage "Sonstiges"'
            ),
        }),
        recommendedBy: Yup.string(),
    }),
    contact: Yup.object()
        .shape({
            salutation: Yup.string().required('Pflichtfeld'),
            title: Yup.string(),
            firstname: Yup.string(),
            lastname: Yup.string().required('Pflichtfeld'),
            company: Yup.string(),
            address: Yup.string(),
            zip: Yup.string(),
            city: Yup.string(),
            country: Yup.string(),
            email: Yup.string().email('Keine valide E-Mail-Adresse'),
            phoneNumbers: Yup.array().of(phoneNumberSchema),
        })
        .when('customer', {
            is: (customer) =>
                customer.infomaterialSentAt && customer.infomaterialSentVia === 'email',
            then: Yup.object().shape({
                email: Yup.string().email('Keine valide E-Mail-Adresse').required('Pflichtfeld'),
            }),
        })
        .when('customer', {
            is: (customer) =>
                customer.infomaterialSentAt && customer.infomaterialSentVia === 'post',
            then: Yup.object().shape({
                address: Yup.string().required('Pflichtfeld'),
                zip: Yup.string().required('Pflichtfeld'),
                city: Yup.string().required('Pflichtfeld'),
                country: Yup.string().required('Pflichtfeld'),
            }),
        }),
});

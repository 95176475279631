import React from 'react';
import PropTypes from 'prop-types';
import ArrivalIcon from 'react-icons/lib/fa/arrow-circle-right';
import DepartureIcon from 'react-icons/lib/fa/arrow-circle-left';
import { CardTable } from 'react-kanban';

import Typography from '../../Modules/mui/Components/Typography';
import CheckIndicator from '../Helpers/CheckIndicator';
import { formatDate } from '../../helpers/tools';
import { CareNeedNurseDeploymentTransferPropType } from '../../proptypes/careneeds';
import NurseDeploymentLabel from '../Helpers/NurseDeploymentLabel';
import { IdPropType } from '../../proptypes/basic';
import CardTableHeader from './CardTableHeader';
import { useDialog } from '../../Modules/dialogs/components/DialogContext';
import {
    TRANSFER_TYPE_ARRIVAL,
    TRANSFER_TYPE_DEPARTURE
} from '../../Modules/nurseTransfers/types';
import { useCanWrite } from '../../Modules/abilities/hooks';
import {
    NURSE_TRANSFER_RESOURCE
 } from '../../Modules/api/resources';

const PICKUP_CHOICES = [
    { id: 'taxi', label: 'Taxi' },
    { id: 'customer_pickup', label: 'Abholung durch Kunden oder Angehörigen' },
    { id: 'other', label: 'Sonstige' },
];

const getTransferRows = ({
    label,
    transfer,
    billing,
    openTransferDialog,
    customerId,
    deploymentId,
    type,
    canWrite,
}) => {

    const rows = [
        {
            key: 'transfer',
            color:
                !(
                    transfer &&
                    (transfer.status === 'details_complete' || transfer.status === 'details_done')
                ) && 'error',
            cells: [
                {
                    key: 'status',
                    label: (
                        <CheckIndicator
                            checked={
                                !!(
                                    transfer &&
                                    (transfer.status === 'details_complete' ||
                                        transfer.status === 'details_done')
                                )
                            }
                            fontSize="small"
                        />
                    ),
                    ignoreHeight: true,
                },
                {
                    key: 'label',
                    label: <Typography variant="body2">{label}</Typography>,
                    flex: 1,
                },
                {
                    key: 'date',
                    label:
                        transfer && transfer.date ? (
                            <Typography emphasize variant="body2">
                                {formatDate(transfer.date)}
                            </Typography>
                        ) : (
                            canWrite ? ('Eintragen') : ('K.A.')
                        ),

                    onClick:    () => {if (canWrite || (transfer && transfer.date)) {
                        openTransferDialog({
                            transferId: transfer ? transfer.id : null,
                            customerId,
                            deploymentId,
                            type,
                        })}},

                },
            ],
        },
    ];

    if (
        (transfer && transfer.transferRequired) ||
        (transfer != null && transfer.transferRequired)
    ) {
        rows.push({
            key: 'pickup',
            color: (!transfer || (transfer.transferRequired && !transfer.pickup)) && 'error',
            cells: [
                {
                    key: 'status',
                    label: (
                        <CheckIndicator
                            checked={!!(transfer && transfer.pickup)}
                            fontSize="small"
                        />
                    ),
                    ignoreHeight: true,
                },
                {
                    key: 'transfer',
                    label: <Typography variant="body2">{label} Transfer</Typography>,
                    flex: 1,
                },
                {
                    key: 'add',
                    label:
                        transfer && transfer.pickup ? (
                            <Typography ellipsis variant="body2">
                                {
                                    PICKUP_CHOICES.find((choice) => choice.id === transfer.pickup)
                                        .label
                                }
                            </Typography>
                        ) : (
                            canWrite ? ('Eintragen') : ('K.A.')
                        ),
                    flex: transfer && transfer.pickup ? 1 : undefined,
                    onClick: () => {if (canWrite || (transfer && transfer.pickup)) {
                        openTransferDialog({
                            transferId: transfer ? transfer.id : null,
                            customerId,
                            deploymentId,
                            type,
                        })}},
                },
            ],
        });
    }

    if (billing !== undefined) {
        rows.push({
            key: 'billing',
            cells: [
                {
                    key: 'status',
                    label: <CheckIndicator checked={!!billing} fontSize="small" />,
                    ignoreHeight: true,
                },
                {
                    key: 'billing',
                    label: <Typography variant="body2">Berechnung vom</Typography>,
                    flex: 1,
                },
                {
                    key: 'date',
                    label: (
                        <Typography emphasize variant="body2">
                            {formatDate(billing)}
                        </Typography>
                    ),
                },
            ],
        });
    }

    return rows;
};

const NurseDeploymentTransfer = ({
    customerId,
    departure,
    type,
    transfer,
    nurseId,
    agencyId,
    billing,
    deploymentId,
}) => {
    const { openTransferDialog } = useDialog();
    const canWriteNurseDeploymentTransfer = useCanWrite(NURSE_TRANSFER_RESOURCE);

    return (
        <>
            <CardTableHeader
                label={<NurseDeploymentLabel type={type} nurseId={nurseId} agencyId={agencyId} />}
                Icon={departure ? DepartureIcon : ArrivalIcon}
            />

            {type !== 'pause' && type !== 'cancellation_period' && (
                <CardTable
                    data={getTransferRows({
                        label: departure ? 'Abreise' : 'Anreise',
                        transfer,
                        billing,
                        openTransferDialog,
                        customerId,
                        deploymentId,
                        type: departure ? TRANSFER_TYPE_DEPARTURE : TRANSFER_TYPE_ARRIVAL,
                        canWrite: canWriteNurseDeploymentTransfer,
                    })}
                />
            )}
        </>
    );
};

NurseDeploymentTransfer.propTypes = {
    customerId: IdPropType.isRequired,
    nurseId: IdPropType.isRequired,
    agencyId: IdPropType,
    transfer: CareNeedNurseDeploymentTransferPropType,
    billing: PropTypes.string,
    departure: PropTypes.bool,
    type: PropTypes.string.isRequired,
    deploymentId: IdPropType.isRequired,
};

NurseDeploymentTransfer.defaultProps = {
    transfer: null,
    agencyId: null,
    billing: undefined,
    departure: false,
};

export default NurseDeploymentTransfer;

import * as PropTypes from 'prop-types';

const types = {
    nurse: 'BK',
    pause: 'Pause',
    cancellation_period: 'Kündigungsfrist',
};

const NurseDeploymentType = ({ type }) => {
    return types[type];
};

NurseDeploymentType.propTypes = {
    type: PropTypes.string.isRequired,
};

NurseDeploymentType.defaultProps = {};

export default NurseDeploymentType;

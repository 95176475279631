/* eslint-disable no-param-reassign */

import urljoin from 'url-join';
import { format, getTime } from 'date-fns';
import logger from '../../logger';

export const DIRECTORY_TYPE = 'directory';

export const createNode = (path, node) => ({
    ...node,
    path,
    isDirectory: node.type === DIRECTORY_TYPE,
    children: node.type === DIRECTORY_TYPE ? [] : null,
    initialized: false,
    loading: false,
});

export const updateNode = (node, children, other) => ({
    ...node,
    initialized: true,
    loading: false,
    ...other,
    children: children.map((child) => child.filename),
});

export const initializeNode = (pathOrPaths, nodes, setNodes, cloud, refresh = false) => {
    const paths = Array.isArray(pathOrPaths) ? pathOrPaths : [pathOrPaths];

    const isNew = paths.reduce((carry, path) => {
        if (!nodes[path]) {
            /* must be set directly to prevent concurrency issues */
            nodes[path] = createNode(path, {
                type: DIRECTORY_TYPE,
            });
            return true;
        }
        return carry;
    }, false);

    const { isChanged, results } = paths.reduce(
        (carry, path) => {
            const node = nodes[path];

            if ((!node.initialized || refresh) && !node.loading) {
                nodes[path].loading = true; /* must be set directly to prevent concurrency issues */

                carry.results.push(
                    cloud
                        .directory(node.path)
                        .then((dir) => ({ dir, path }))
                        .catch((error) => {
                            return { error, path };
                        })
                );
                carry.isChanged = true;
            }

            return carry;
        },
        { isChanged: false, results: [] }
    );

    if (isNew || isChanged) {
        setNodes({ ...nodes });

        return Promise.all(results).then((result) => {
            const err = result.reduce((carry, { dir = [], path, error }) => {
                dir.forEach((entry) => {
                    nodes[entry.filename] = createNode(entry.filename, entry);
                });

                nodes[path] = updateNode(nodes[path], dir, { error });

                return carry || error;
            }, false);

            setNodes({ ...nodes });

            if (err) {
                throw err;
            }
        });
    }

    return Promise.resolve();
};

export const groupPathsByType = (paths, nodes) =>
    paths.reduce(
        ([dirs, files], path) => {
            if (nodes[path].type === DIRECTORY_TYPE) {
                dirs.push(path);
            } else {
                files.push(path);
            }
            return [dirs, files];
        },
        [[], []]
    );

export const getNodeSortValue = (nodes) => (path, orderBy) => {
    const node = nodes[path];

    switch (orderBy) {
        case 'name':
            return node.filename.toLowerCase();
        case 'modified':
            return getTime(new Date(node.lastmod));
        default:
            return node.filename;
    }
};

export const asRelativePath = (path) => {
    if (path && path.startsWith('/') && path.length > 1) {
        return path.substring(1);
    }
    return path;
};

export const asAbsolutePath = (path) => {
    if (path && !path.startsWith('/')) {
        return `/${path}`;
    }
    return path;
};

export const getFileName = (filename) => {
    if (!filename) {
        return null;
    }

    const parts = filename.endsWith('/')
        ? filename.substring(0, filename.length - 1).split('/')
        : filename.split('/');
    const last = parts.pop();
    return last.length > 0 ? last : '/';
};
export const getFilePath = (filename, prefix = null) => {
    if (!filename) {
        return null;
    }

    const parts = filename.split('/');
    parts.pop(); // remove file name

    if (parts.length === 1 && parts[0] === '') {
        return prefix ? urljoin(prefix, '/') : '/';
    }

    return prefix ? urljoin(prefix, parts.join('/')) : parts.join('/');
};
export const formatFileSize = (filesize) => {
    const kb = filesize / 1024;
    if (kb < 100) {
        return `${kb.toFixed(2)} KB`;
    }

    const mb = kb / 1024;
    if (mb < 100) {
        return `${mb.toFixed(2)} MB`;
    }

    const gb = mb / 1024;
    return `${gb.toFixed(2)} GB`;
};

export const attachmentsToFieldValue = (
    attachments = [],
    filepathField = 'filepath',
    filenameField = 'filename'
) =>
    attachments.map((attachment) => {
        const filepath = attachment[filepathField];
        return {
            ...attachment,
            file: null,
            uploaded: true,
            filepath,
            path: getFilePath(filepath),
            filename: getFileName(attachment[filenameField]),
        };
    });

export const filesToFieldValue = (files = [], initial = [], path = null, max = null) => {
    const values = files.reduce(
        (carry, file) => {
            const attachment = {
                file,
                filename: file.name,
                uploaded: false,
                path,
                metadata: {},
            };

            const index = carry.findIndex(
                (item) => item.file && item.file.path === attachment.file.path
            );
            if (index >= 0) {
                // eslint-disable-next-line no-param-reassign
                carry[index] = attachment;
            } else {
                carry.push(attachment);
            }
            return carry;
        },
        [...initial]
    );

    return max ? values.slice(0, max) : values;
};

const getUploadPath = (uploaded, path, filename, root, overwrite) => {
    if (!path || !filename) {
        return filename;
    }

    if (!overwrite && !uploaded) {
        const suffix = format(new Date(), 'ddMMyyHHmmss');

        const parts = filename.split('.');
        const beforeLast = Math.max(0, parts.length - 2);
        parts[beforeLast] = `${parts[beforeLast]} ${suffix}`;

        return urljoin(root, path, parts.join('.'));
    }

    return urljoin(root, path, filename);
};

export const fieldValueToUploadable = (values, root, onProgress, onError) => {
    const existing = values
        .filter(({ file, destroy }) => !file && !destroy)
        .map(({ path, filename, ...data }) => ({
            ...data,
            path: urljoin(root, path, filename),
            onError,
        }));

    const uploads = values
        .filter(({ file, destroy }) => file && !destroy)
        .map(({ uploaded, path, filename, file, metadata, overwrite, ...data }) => {
            const uploadPath = getUploadPath(uploaded, path, filename, root, overwrite);
            return {
                ...data,
                path: uploadPath,
                file,
                metadata,
                onProgress: (progress) => {
                    const percentage = (progress.loaded / progress.total) * 100;
                    logger.info(`upload ${filename}: ${percentage}%`);
                    if (onProgress) {
                        onProgress(percentage);
                    }
                },
                onError,
                config: {
                    overwrite,
                },
                replace: existing.some((_existing) => _existing.path === uploadPath),
            };
        });

    const destroys = values
        .filter(({ file, destroy, path, filename }) => {
            if (file || !destroy) {
                return false;
            }

            const uploadPath = urljoin(root, path, filename);
            const upload = uploads.find((_upload) => _upload.path === uploadPath);
            if (upload) {
                upload.replace = true;
            }
            return !upload;
        })
        .map(({ path, filename, ...data }) => ({
            ...data,
            path: urljoin(root, path, filename),
            onError,
        }));

    return [uploads, destroys, existing];
};

export const fieldValueToAttachments = (attachments) =>
    attachments.map((attachment) => ({
        id: attachment.id,
        name: attachment.name,
        path: urljoin(attachment.path, attachment.name),
        overwrite: attachment.overwrite,
    }));

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import MailView from './MailView';
import InboxList from './InboxList';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },

    list: {
        maxWidth: 450,
        height: '100%',
        overflowY: 'auto',
        borderRight: `1px solid ${theme.palette.grey[300]}`,
    },

    content: {
        overflowX: 'auto',
        height: '100%',
    },
}));

const createListId = (customerId = null) => {
    if (customerId) {
        return `emails.${customerId}.list`;
    }
    return 'emails.list';
};

const MailInbox = ({ customerId }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(null);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={4} className={classes.list}>
                <InboxList
                    listId={createListId(customerId)}
                    customerId={customerId}
                    selected={selected}
                    onSelect={setSelected}
                />
            </Grid>
            <Grid item xs className={classes.content}>
                <MailView mailId={selected} />
            </Grid>
        </Grid>
    );
};

MailInbox.propTypes = {
    customerId: IdPropType,
};

MailInbox.defaultProps = {
    customerId: null,
};

export default MailInbox;

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { IdPropType } from '../../proptypes/basic';
import NurseName from '../../Modules/nurses/components/NurseName';
import Agency from '../../Modules/agencies/components/Agency';

const NurseDeploymentLabel = ({ type, nurseId, agencyId }) => {
    let name = null;
    if (type === 'pause') {
        name = (
            <>
                Pause
                <br />
            </>
        );
    } else if (type === 'cancellation_period') {
        name = (
            <>
                Kündigungsfrist
                <br />
            </>
        );
    } else {
        name = <NurseName nurseId={nurseId} />;
    }

    return (
        <>
            {name}
            {agencyId && (
                <Typography variant="body2" color="textSecondary">
                    <Agency agencyId={agencyId} />
                </Typography>
            )}
        </>
    );
};

NurseDeploymentLabel.propTypes = {
    type: PropTypes.string.isRequired,
    nurseId: IdPropType,
    agencyId: IdPropType,
};

NurseDeploymentLabel.defaultProps = {
    nurseId: null,
    agencyId: null,
};

export default NurseDeploymentLabel;

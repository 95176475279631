import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { isPast, parseISO } from 'date-fns';
import Select from '../../form/components/Select';
import { IdPropType } from '../../../proptypes/basic';
import CareNeed from './CareNeed';
import { useCareNeedsByCustomerId } from '../careNeedsSlice';

const CareNeedSelect = ({ name, customerId, label }) => {
    const [list, { loading }] = useCareNeedsByCustomerId(customerId);

    const options = useMemo(() => {
        return list
            .filter((careNeed) => {
                return !careNeed.careTill || !isPast(parseISO(careNeed.careTill));
            })
            .map((careNeed) => ({
                label: <CareNeed careNeedId={careNeed.id} fetch />,
                value: careNeed.id,
            }));
    }, [list]);

    return <Select name={name} options={options} loading={loading} label={label} fullWidth />;
};

CareNeedSelect.propTypes = {
    name: PropTypes.string.isRequired,
    customerId: IdPropType.isRequired,
    label: PropTypes.string,
};

CareNeedSelect.defaultProps = {
    label: null,
};

export default CareNeedSelect;

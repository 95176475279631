import React from 'react';
import PropTypes from 'prop-types';
import { IdPropType } from '../../../proptypes/basic';
import { useContactsByCustomerIdWithType } from '../hooks';
import ContactInfo from './ContactInfo';

const ContactWithTypeInfo = ({ customerId, type }) => {
    const [[contractingPartner, ASDF]] = useContactsByCustomerIdWithType(customerId, type);
    return contractingPartner ? <ContactInfo contactId={contractingPartner.id} secondContactId={ASDF?.id} /> : null;
};

ContactWithTypeInfo.propTypes = {
    customerId: IdPropType,
    type: PropTypes.string.isRequired,
};

ContactWithTypeInfo.defaultProps = {
    customerId: null,
};

export default ContactWithTypeInfo;

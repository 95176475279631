import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Select from '../../form/components/Select';
import { IdPropType } from '../../../proptypes/basic';
import Agency from '../../agencies/components/Agency';
import ContractDate from './ContractDate';
import { DATE_FORMAT } from '../../date/utils';
import { useContractsByCustomerId } from '../contractsSlice';
import { AGENCY_CONTRACT } from '../contractTypes';

const groupPresent = 'Aktuelle';
const groupPast = 'Vergangene';
const groupOther = 'Andere';

const determineGroup = (contract) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (contract.endAt && moment(contract.endAt, DATE_FORMAT).isBefore(today)) {
        return groupPast;
    }
    if (!contract.endAt || moment(contract.endAt, DATE_FORMAT).isAfter(today)) {
        return groupPresent;
    }
    return groupOther;
};

const determineActive = (contract) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (contract.endAt && moment(contract.endAt, DATE_FORMAT).isBefore(today)) {
        return true;
    }
    return false;
};

const ContractSelect = ({ name, customerId, label, disabled, filter, ...other }) => {
    const [contracts, { loading }] = useContractsByCustomerId(customerId);

    const options = useMemo(
        () =>
            (contracts || [])
                .filter(
                    (contract) =>
                        !contract.parentContractId &&
                        contract.type === AGENCY_CONTRACT &&
                        (!filter || filter(contract))
                )
                .map((contract) => ({
                    label: (
                        <Grid container spacing={2} direction="row">
                            <Grid item xs>
                                <Agency agencyId={contract.agencyId} />
                            </Grid>
                            <Grid item>
                                (<ContractDate contract={contract} />)
                            </Grid>
                        </Grid>
                    ),
                    value: contract.id,
                    disabled: determineActive(contract),
                    group: determineGroup(contract),
                })),
        [contracts, filter]
    );

    return (
        <Select
            name={name}
            options={options}
            label={label}
            disabled={disabled}
            loading={loading}
            fullWidth
            {...other}
        />
    );
};

ContractSelect.propTypes = {
    name: PropTypes.string.isRequired,
    customerId: IdPropType.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    filter: PropTypes.func,
};

ContractSelect.defaultProps = {
    label: null,
    disabled: false,
    filter: null,
};

export default ContractSelect;

import React, { useMemo,useState } from 'react';
import PropTypes from 'prop-types';
import  { ExportLatestQuestionaireDataJsonToCsv }  from 'Modules/questionnaires/tools/tools';
import LoadingBox from '@pzh/crm/src/Modules/loading/components/LoadingBox';
import { useQuestionnairesByContactId } from '@pzh/crm/src/Modules/questionnaires/questionnairesSlice';
import { useContactsByCustomerId } from 'Modules/contacts/contactsSlice';
import { useApi } from 'Modules/api/components/ApiProvider';

export const CustomerLatestQuestionaireCSVAttachment = ({customerId, name, value, onChange }) => {
    const { cloud } = useApi();

    const handleChange = useMemo(
        () => (value) => {
            const newValue = value;
            onChange(name, newValue);
        },
        [onChange, name]
    );
    const [exportComplete, setExportComplete] = useState(false);
    const [exportError, setExportError] = useState(false);

    const [contacts] = useContactsByCustomerId(customerId);

    const patient = contacts.filter((c) => c.isPatient);

    // get the primary contact id
    const primaryContact = contacts.filter((c) => c.isPrimaryContact);

    const [
        questionnaires,
        { loading: questionnaireLoading, initialized: questionnaireInitialized, error: questionnaireError },
    ] = useQuestionnairesByContactId(patient[0]?.id);

    const error = questionnaireError;
    const loading = questionnaireLoading;
    const initialized = questionnaireInitialized;

    // always show newest version
    const [questionnaire] = questionnaires.slice(-1);

    if (contacts && patient && primaryContact && questionnaire && !exportComplete)
    {
        const csv_data = ExportLatestQuestionaireDataJsonToCsv(patient[0], questionnaire, primaryContact[0]);
        // create a blob file with the csv-data
        const csv_blob_data = new Blob([csv_data], { type: 'text/csv' });

        setExportComplete(true);

        const upload_data = {
            path: "/PzH/Kunden/"+customerId+"/",
            file: "questionaire_"+customerId+".csv"
        };

        let upload_full_path = `${upload_data.path}${upload_data.file}`;

        // create a temp file from the Blob data => we need a temp path for the upload process
        let temporaryFile = new File([csv_blob_data], "tempfile.csv", { type: 'text/csv' });

        cloud.upload(upload_full_path, temporaryFile)
            .then(upload_result => {
                //console.log("Upload result:", upload_result);
                handleChange(upload_full_path);
            })
            .catch(upload_error => {
                console.error("ERROR: Uploading eb-csv file:", upload_error);
                setExportError(true);
            });

        handleChange(upload_full_path);
    }

    if (error) {
        exportError(true);
    }

    return (
        <LoadingBox loading={loading} initialized={initialized}>
            <p>Erhebungsboden (CSV)
                {!exportError && !exportComplete && ' - Wird geladen...'}
                {!exportError && exportComplete && ' - Als Anhang bereit'}
                {exportError &&  ' - [Fehler beim Fehler beim Laden des Erhebungsbogen!]'}
            </p>
        </LoadingBox>
    );
};

CustomerLatestQuestionaireCSVAttachment.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

CustomerLatestQuestionaireCSVAttachment.defaultProps = {};

export default CustomerLatestQuestionaireCSVAttachment;

import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, MenuItem, FormControl, Select } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ForwardIcon from '@material-ui/icons/Forward';
import ReplyIcon from '@material-ui/icons/Reply';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

import { useDispatch } from 'react-redux';
import { Category, FiberManualRecordOutlined } from '@material-ui/icons';
import { IdPropType } from '../../../proptypes/basic';
import Button from '../../buttons/components/Button';
import { updateEmail, useEmail } from '../emailsSlice';
import { EMAIL_RESOURCE } from '../../api/resources';
import { useDialog } from '../../dialogs/components/DialogContext';
import { formatDate } from '../../date/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },

    marginRight: {
        marginRight: theme.spacing(2),
    },
    inputBase: {
        '& .MuiInputBase-input': {
            padding: '0px',
            paddingRight: '5px',
            paddingLeft: '5px',
            paddingTop: '5px',
        },
    },
}));

const MAIL_MODE_REPLY = 'REPLY';
const MAIL_MODE_FORWARD = 'FORWARD';

const prepareMailParameters = (mail, mode = MAIL_MODE_REPLY) => {
    const receiver = mail.emailReceivers.find((r) => r.type === 'from');
    const orig_receivers = mail.emailReceivers
        .filter((r) => r.type === 'to')
        .map((r) =>
            r.email_address.name && r.email_address.name.length != 0
                ? `${r.email_address.name} <${r.email_address.address}>`
                : `${r.email_address.address}`
        );

    //console.log('orig-rcv:', mail.emailReceivers, orig_receivers);
    const cc = mail.emailReceivers
        .filter((r) => r.type === 'cc')
        .map((r) => r.email_address.address);

    const address = receiver?.email_address?.address;
    const name = receiver?.email_address?.name || 'Unbekannt';

    const date = formatDate(mail.received_at, 'DD.MM.YYYY HH:mm');
    const readableDate = formatDate(mail.received_at, 'dddd, DD.MMMM,YYYY HH:mm');

    switch (mode) {
        case MAIL_MODE_REPLY:
            return {
                parentId: mail.id,
                customerId: mail.customer_id,
                receivers: [address],
                cc,
                subject: `Aw: + ${mail.subject}`,
                text: `<br><br><br>
                Am ${date} schrieb ${name} <${address}>:<br><br>

                > ${mail.body.replaceAll('\n', '<br>> ')}
                `,
            };

        case MAIL_MODE_FORWARD:
        default:
            return {
                parentId: mail.id,
                customerId: mail.customer_id,
                attachments: mail.attachments.map((a) => ({
                    filename: a.name,
                    path: a.path,
                })),
                subject: `WG: ${mail.subject}`,
                text: `<b>Von:</b> ${name} <${address}><br>
                <b>Gesendet:</b> ${readableDate}<br>
                <b>An:</b> ${orig_receivers?.join(', ')}<br>
                <b>Betreff:</b> ${mail.subject}<br><br>
                ${mail.body.replaceAll('\n', '<br> ')}
                `,
            };
    }
};

const MailActions = ({ mailId }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [mail] = useEmail(mailId);

    const { openSendMailDialog } = useDialog();

    const handleReply = useCallback(() => {
        openSendMailDialog({
            entity: 'Customer',
            entityId: mail.customer_id,
            templateId: null,
            prefill: prepareMailParameters(mail, MAIL_MODE_REPLY),
        });
    }, [openSendMailDialog, mail]);

    const handleForward = useCallback(() => {
        openSendMailDialog({
            entity: 'Customer',
            entityId: mail.customer_id,
            templateId: null,
            prefill: prepareMailParameters(mail, MAIL_MODE_FORWARD),
        });
    }, [openSendMailDialog, mail]);

    const handleDone = () => {
        if (mailId) {
            const date = new Date();
            dispatch(
                updateEmail({
                    id: mailId,
                    read_at: mail.read_at === null ? date : mail.read_at,
                    checked_at: mail.checked_at === null ? date : null,
                })
            );
        }
    };

    const handleRead = () => {
        if (mailId) {
            dispatch(
                updateEmail({
                    id: mailId,
                    read_at: mail.read_at === null ? new Date() : null,
                })
            );
        }
    };

    const handleCategory = (e) => {
        e.preventDefault();
        if (mailId) {
            dispatch(
                updateEmail({
                    id: mailId,
                    category: e.target.value,
                })
            );
        }
    };

    return (
        <Grid container justify="space-between" className={classes.root}>
            <Grid item>
                <Button
                    disabled={!mailId}
                    subject={mail || EMAIL_RESOURCE}
                    className={classes.marginRight}
                    color="primary"
                    variant="contained"
                    onClick={handleReply}
                    startIcon={<ReplyIcon />}
                >
                    Antworten
                </Button>
                <Button
                    startIcon={<ForwardIcon />}
                    disabled={!mailId}
                    subject={mail || EMAIL_RESOURCE}
                    color="primary"
                    variant="contained"
                    onClick={handleForward}
                >
                    Weiterleiten
                </Button>
            </Grid>
            <Grid item>
                <Button
                    startIcon={<Category />}
                    disabled={!mailId}
                    color="primary"
                    className={classes.marginRight}
                    variant="contained"
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            labelId="catselect"
                            id="catselect"
                            label="Kategorie"
                            value={mail && mail.category ? mail.category : ''}
                            onChange={handleCategory}
                            style={{ background: 'transparent' }}
                            className={classes.inputBase}
                        >
                            <MenuItem value={''} selected>
                                leer
                            </MenuItem>
                            <MenuItem value={'grün'}>grün</MenuItem>
                            <MenuItem value={'gelb'}>gelb</MenuItem>
                            <MenuItem value={'rot'}>rot</MenuItem>
                        </Select>
                    </FormControl>
                </Button>
                <Button
                    startIcon={
                        mail && mail.read_at ? <FiberManualRecord /> : <FiberManualRecordOutlined />
                    }
                    className={classes.marginRight}
                    disabled={!mailId}
                    color="primary"
                    variant="contained"
                    onClick={handleRead}
                >
                    {mail && mail.read_at ? 'Nicht gelesen' : 'Gelesen'}
                </Button>
                <Button
                    startIcon={<DoneAllIcon />}
                    disabled={!mailId}
                    color="primary"
                    variant="contained"
                    onClick={handleDone}
                >
                    {mail && mail.checked_at ? 'Nicht Erledigt' : 'Erledigt'}
                </Button>
            </Grid>
        </Grid>
    );
};

MailActions.propTypes = {
    mailId: IdPropType,
};

MailActions.defaultProps = {
    mailId: null,
};

export default MailActions;

import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import isFunction from 'lodash/isFunction';
import Table from '../Table';
import TableRowStoreContainer from '../TableRowStoreContainer';
import TableContext from '../TableContext';
import CreateResourceButton from './CreateResourceButton';
import {
    TableActionPropType,
    TableColumnsPropType,
    TableExpandPropType,
    TableOrderPropType,
    WithConfigPropType,
} from '../../proptypes';
import TableRowDetails from '../TableRowDetails';
import ArchiveIconButton from '../../../buttons/components/ArchiveIconButton';
import EditIconButton from '../../../buttons/components/EditIconButton';
import DeleteIconButton from '../../../buttons/components/DeleteIconButton';
import DeleteButton from '../../../buttons/components/DeleteButton';
import RestoreButton from '../../../buttons/components/RestoreButton';
import ArchiveButton from '../../../buttons/components/ArchiveButton';
import RestoreIconButton from '../../../buttons/components/RestoreIconButton';
import EditButton from '../../../buttons/components/EditButton';
import { useResourceList } from '../../../lists/hooks';
import ResourcePagination from './ResourcePagination';
import { getResourceIdField } from '../../../store/resource/utils';
import { IdPropType } from '../../../../proptypes/basic';
import { CriteriaPropType } from '../../../lists/proptypes';
import TableCollapse from './TableCollapse';

// window.addEventListener('popstate', function(event) {
//     localStorage.setItem("tableBack", "true");
//     localStorage.setItem("changeBool", "false");
// });

const defaultComponents = {
    TableRow: TableRowStoreContainer,
    TableRowDetails,
};

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },

    createButton: {
        display: 'flex',
        marginLeft: 'auto',
    },

    pagination: {
        marginBottom: theme.spacing(3),
    },
}));

const isLoading = ({ variant, loading, initialized, data, dataLoading, dataInitialized }) => {
    if (variant === 'collapse' && ((data && dataInitialized) || (!data && initialized))) {
        return false;
    }
    return data ? dataLoading : loading;
};

const getActions = ({
    actions,
    onEdit,
    onEditPath,
    onArchive,
    onDelete,
    editDisabled,
    archiveDisabled,
    deleteDisabled,
    addingDisabled,
}) => {
    const merged = actions ? [...actions] : [];

    if (onEdit) {
        merged.push({
            key: 'edit',
            action: ({ data }) => (
                <EditButton
                    onClick={() => onEdit(data.id)}
                    disabled={!!(data.deletedAt || (editDisabled && editDisabled(data)))}
                    subject={data}
                    path={onEditPath ? onEditPath(data.id) : null}
                />
            ),
            compact: ({ data }) => (
                <EditIconButton
                    onClick={() => onEdit(data.id)}
                    disabled={!!(data.deletedAt || (editDisabled && editDisabled(data)))}
                    subject={data}
                    size="small"
                    path={onEditPath ? onEditPath(data.id) : null}
                />
            ),
        });
    }

    if (onArchive) {
        merged.push({
            key: 'archive',
            action: ({ data }) =>
                data.deletedAt ? (
                    <RestoreButton
                        onClick={() => onArchive(data.id, true)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                    />
                ) : (
                    <ArchiveButton
                        onClick={() => onArchive(data.id, false)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                        size="small"
                    />
                ),
            compact: ({ data }) =>
                data.deletedAt ? (
                    <RestoreIconButton
                        onClick={() => onArchive(data.id)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                    />
                ) : (
                    <ArchiveIconButton
                        onClick={() => onArchive(data.id)}
                        disabled={archiveDisabled && archiveDisabled(data)}
                        subject={data}
                        size="small"
                    />
                ),
        });
    }

    if (onDelete) {
        merged.push({
            key: 'delete',
            action: ({ data }) => (
                <DeleteButton
                    onClick={() => onDelete(data.id)}
                    disabled={deleteDisabled && deleteDisabled(data)}
                    subject={data}
                />
            ),
            compact: ({ data }) => (
                <DeleteIconButton
                    onClick={() => onDelete(data.id)}
                    disabled={deleteDisabled && deleteDisabled(data)}
                    subject={data}
                    size="small"
                />
            ),
        });
    }

    return merged;
};

const ResourceTable = ({
    data,
    loading: dataLoading,
    initialized: dataInitialized,
    dataIds: overrideDataIds,
    resource,
    listId: primaryListId,
    columns,
    createLabel,
    onCreate,
    onEdit,
    onEditPath,
    onArchive,
    onDelete,
    addingDisabled,
    editDisabled,
    archiveDisabled,
    deleteDisabled,
    actions,
    expand,
    showActionsInline,
    initialOrderBy,
    orderRanks,
    onClick,
    'data-test-id': dataTestId,
    'aria-label': ariaLabel,
    fetchParams,
    staticParams,
    autoload,
    refresh,
    with: withKeys,
    criteria,
    limit,
    variant,
}) => {
    const classes = useStyles();

    const listId = primaryListId || resource;

    const {
        dataIds,
        dataSelector,
        relatedSelector,
        loading,
        initialized,
        handlePage,
        handleToggleAll,
        orderBy,
        handleOrderBy,
    } = useResourceList({
        listId,
        resource,
        fetchParams,
        staticParams,
        initialOrderBy,
        autoload,
        refresh,
        with: withKeys,
        criteria,
        limit,
    });

    const mergedActions = useMemo(
        () =>
            getActions({
                actions,
                onEdit,
                onEditPath,
                onArchive,
                onDelete,
                editDisabled,
                archiveDisabled,
                deleteDisabled,
                addingDisabled,
            }),
        [
            onEdit,
            onEditPath,
            onArchive,
            onDelete,
            actions,
            editDisabled,
            archiveDisabled,
            deleteDisabled,
            addingDisabled,
        ]
    );

    /*
     * [QoL]: use `<resource>Id` as default id field name when using an expand component
     */
    const extendedExpand = useMemo(() => {
        if (!expand) {
            return null;
        }

        return {
            as: getResourceIdField(resource),
            ...(isFunction(expand) ? { component: expand } : expand),
        };
    }, [expand, resource]);

    return (
        <TableContext.Provider value={defaultComponents}>
            {(!addingDisabled && onCreate) && (
                <CreateResourceButton
                    label={createLabel}
                    listId={listId}
                    onClick={onCreate}
                    resource={resource}
                    data-test-id={`${dataTestId}CreateButton`}
                    className={classes.createButton}
                    disabled={addingDisabled}
                />
            )}
            <Table
                dataIds={overrideDataIds || dataIds}
                data={data}
                columns={columns}
                dataSelector={dataSelector}
                relatedSelector={relatedSelector}
                loading={isLoading({
                    variant,
                    loading,
                    data,
                    dataLoading,
                    initialized,
                    dataInitialized,
                })}
                initialized={data ? dataInitialized : initialized}
                data-test-id={dataTestId}
                aria-label={ariaLabel}
                actions={mergedActions}
                expand={extendedExpand}
                showActionsInline={showActionsInline}
                orderBy={orderBy}
                onOrderBy={handleOrderBy}
                orderRanks={orderRanks}
                onClick={onClick}
                listId={listId}
            />
            {!data && variant === 'pagination' && (
                <ResourcePagination
                    listId={listId}
                    onChange={handlePage}
                    className={classes.pagination}
                />
            )}
            {variant === 'collapse' && (
                <TableCollapse
                    listId={listId}
                    limit={limit}
                    onToggle={handleToggleAll}
                    loading={data ? dataLoading : loading}
                    initialized={data ? dataInitialized : initialized}
                />
            )}
        </TableContext.Provider>
    );
};

ResourceTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
    initialized: PropTypes.bool,
    dataIds: PropTypes.arrayOf(IdPropType),
    resource: PropTypes.string.isRequired,
    listId: PropTypes.string,
    columns: TableColumnsPropType.isRequired,
    createLabel: PropTypes.string,
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    onEditPath: PropTypes.func,
    onArchive: PropTypes.func,
    onDelete: PropTypes.func,
    editDisabled: PropTypes.func,
    addingDisabled: PropTypes.bool,
    archiveDisabled: PropTypes.func,
    deleteDisabled: PropTypes.func,
    fetchParams: PropTypes.shape({}),
    staticParams: PropTypes.shape({}),
    actions: PropTypes.arrayOf(TableActionPropType),
    expand: TableExpandPropType,
    showActionsInline: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    initialOrderBy: PropTypes.arrayOf(TableOrderPropType),
    orderRanks: PropTypes.number,
    autoload: PropTypes.bool,
    refresh: PropTypes.bool,
    onClick: PropTypes.func,
    with: WithConfigPropType,
    'data-test-id': PropTypes.string,
    'aria-label': PropTypes.string,
    criteria: CriteriaPropType,
    limit: PropTypes.number,
    variant: PropTypes.string,
};

ResourceTable.defaultProps = {
    data: null,
    loading: false,
    initialized: false,
    dataIds: null,
    listId: null,
    createLabel: null,
    onCreate: null,
    onEdit: null,
    onEditPath: null,
    onArchive: null,
    onDelete: null,
    editDisabled: null,
    archiveDisabled: null,
    deleteDisabled: null,
    fetchParams: null,
    staticParams: null,
    actions: null,
    expand: null,
    showActionsInline: undefined,
    initialOrderBy: undefined,
    orderRanks: undefined,
    autoload: false,
    refresh: false,
    onClick: null,
    with: null,
    'data-test-id': null,
    'aria-label': null,
    criteria: null,
    limit: null,
    variant: 'pagination',
    addingDisabled: false,
};

export default ResourceTable;

import React, { useCallback, useMemo } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SendIcon from '@material-ui/icons/Send';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../form/components/TextInput';
import Form from '../../../form/components/Form';
import Select from '../../../form/components/Select';
import Editor from '../../../form/components/Editor';
import MultiChipSelect from '../../../form/components/MultiChipSelect';
import { useEmailTemplate } from '../../../mailTemplates/emailTemplateSlice';
import {
    selectAllEmailReceiverGroups,
    useEmailReceiverGroups,
} from '../../emailReceiverGroupSlice';
import FileTreeAttachments from '../../../files/components/FileTree/FileTreeAttachments';
import { useContactsByCustomerId } from '../../../contacts/contactsSlice';
import { CONTRACTING_PARTNER_CONTACT, useContactsByCustomerIdWithType } from '../../../contacts/hooks';
import { useContractsByCustomerId } from '../../../contracts/contractsSlice';
import { selectCustomerById } from '../../../customers/customersSlice';
import { EMAIL_RECEIVER_GROUPS } from '../../../mailTemplates/components/MailTemplateForm';
import SubmitButton from '../../../form/components/SubmitButton';
import { useApi } from '../../../api/components/ApiProvider';
import { EMAIL_RESOURCE } from '../../../api/resources';
import { selectAllAgencies } from '../../../agencies/agenciesSlice';
import { selectAllLocations, useLocations } from '../../../locations/locationsSlice';
import { replaceEditorTags } from '../../../mailTemplates/utils';
import { IdPropType, RefPropType } from '../../../../proptypes/basic';
import { MailAttachmentPropType, PrefillMailPropType } from '../../proptypes';
import { sendMailSchema } from '../../schema';
import { selectAllEmployees, selectEmployeeById } from '../../../employees/employeesSlice';
import { asAbsolutePath } from '../../../files/utils';
import { useUser } from '../../../auth/hooks';
import { selectAllEmailInboxes, useEmailInboxes } from '../../../emailInboxes/emailInboxesSlice';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: theme.spacing(2),
    },

    end: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dividerMargin: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));

const getCloudRoots = (customer) =>
    customer ? ['/PzH/Anhänge', asAbsolutePath(customer.cloudFolder)] : ['/PzH/Anhänge'];

const SendMailForm = ({
    onSent,
    entity,
    entityId,
    templateId,
    submitRef,
    attachments,
    extraEntities,
    prefill,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const api = useApi();

    const customerId = entityId;

    const [contacts] = useContactsByCustomerId(customerId);

    const [contractingPartners] = useContactsByCustomerIdWithType(
        customerId,
        CONTRACTING_PARTNER_CONTACT
    );

    useLocations();
    const [contracts] = useContractsByCustomerId(customerId);
    useEmailReceiverGroups();

    const [emailTemplate] = useEmailTemplate(templateId);

    useEmailInboxes();
    const emailInboxes = useSelector(selectAllEmailInboxes);
    const fromAddressOptionsNotEditable = useMemo(() => {
        return emailInboxes
            .filter((emailInbox) => emailTemplate && emailInbox.id === emailTemplate.emailInboxId)
            .map((emailInbox) => ({
                label: emailInbox.name,
                value: emailInbox.name,
            }));
    }, [emailInboxes, emailTemplate]);

    const currentUser = useUser();

    const currentEmployee = useSelector((state) =>
        selectEmployeeById(state, currentUser.employeeId)
    );

    const locations = useSelector(selectAllLocations);
    const employees = useSelector(selectAllEmployees);

    const agencies = useSelector(selectAllAgencies);
    const customer = useSelector((state) => selectCustomerById(state, customerId));
    const receiverGroups = useSelector(selectAllEmailReceiverGroups);

    const roots = getCloudRoots(customer);

    const defaultEmail = useMemo(() => {
        return currentEmployee.emailInboxes.map((e) => e.isDefault).indexOf(1);
    }, [currentEmployee]);

    const fromAddresses = useMemo(() => {
        return currentEmployee.emailInboxes.map((ibx) => ibx.name);
    }, [currentEmployee]);
    const fromAddressOptions = useMemo(() => {
        return fromAddresses.map((addr) => ({
            label: addr,
            value: addr,
        }));
    }, [fromAddresses]);

    const allAttachments = useMemo(() => {
        const result = [];

        if (attachments) {
            attachments.forEach((item) => result.push(item));
        }

        if (emailTemplate && emailTemplate.attachments) {
            emailTemplate.attachments
                .filter((att) => att.type === 'static')
                .forEach((att) =>
                    result.push({
                        path: att.path,
                        locked: !emailTemplate.attachmentsStaticEditable,
                    })
                );
        }

        return result;
    }, [attachments, emailTemplate]);

    const receiverOptions = useMemo(() => {
        const result = [];

        const iterateGroups = emailTemplate
            ? emailTemplate.receiverGroups
            : receiverGroups.map((g) => g.id);

        iterateGroups.forEach((id) => {
            const group = receiverGroups.find((g) => g.id === id);

            if (!group) {
                return;
            }

            switch (group.name) {
                case 'customer.display_contact':
                    contacts
                        .filter((contact) => {
                            return contact.isPrimaryContact;
                        })
                        .filter((contact) => {
                            return !!contact.email;
                        })
                        .map((contact) => {
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: `${contact.firstname || ''} ${contact.lastname} <${
                                    contact.email
                                }>`,
                                value: `contact_${contact.id}`,
                                contact,
                            };
                        })
                        .forEach((i) => result.push(i));

                    break;
                case 'customer.contract_contact':
                    contacts
                        .filter((contact) => {
                            return contact.isContractingPartner;
                        })
                        .filter((contact) => {
                            return !!contact.email;
                        })
                        .map((contact) => {
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: `${contact.firstname || ''} ${contact.lastname} <${
                                    contact.email
                                }>`,
                                value: `contact_${contact.id}`,
                                contact,
                            };
                        })
                        .forEach((i) => result.push(i));

                    break;
                case 'customer.nurse_suggestions_contact':
                    contacts
                        .filter((contact) => {
                            return contact.sendNurseSuggestions;
                        })
                        .filter((contact) => {
                            return !!contact.email;
                        })
                        .map((contact) => {
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: `${contact.firstname || ''} ${contact.lastname} <${
                                    contact.email
                                }>`,
                                value: `contact_${contact.id}`,
                                contact,
                            };
                        })
                        .forEach((i) => result.push(i));

                    break;
                case 'internal_accounts':
                    emailInboxes
                        .filter((emailInbox) => {
                            const label = emailInbox.slug || emailInbox.name;
                            return !label.toLowerCase().includes('zzz');
                        })
                        // BAD IDEA: filter buchhaltung to prevent it showing up as receiver
                        .filter((emailInbox) => {
                            const label = emailInbox.slug || emailInbox.name;
                            return !label.toLowerCase().includes('buchhaltung');
                        })
                        .map((emailInbox) => {
                            const label = emailInbox.slug || emailInbox.name;
                            const arr = label.split(' ');
                            for (var i = 0; i < arr.length; i++) {
                                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                            }
                            //Join all the elements of the array back into a string
                            //using a blankspace as a separator
                            const label2 = arr.join(' ');
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: label2,
                                value: `internal_${emailInbox.id}`,
                            };
                        })
                        .forEach((i) => result.push(i));
                    break;
                case 'agencies':
                    agencies
                        .filter((agency) => {
                            const label = agency.matchCode || agency.name;
                            return !label.toLowerCase().includes('zzz');
                        })
                        .map((agency) => {
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: agency.matchCode || agency.name,
                                value: `agency_${agency.id}`,
                            };
                        })
                        .forEach((i) => result.push(i));
                    break;
                case 'customer.location': {
                    const location = customer
                        ? locations.find(({ id }) => id === customer.locationId)
                        : undefined;
                    if (location) {
                        [
                            {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: `${location.name} <${location.matchCode}>`,
                                value: `location_${location.id}`,
                            },
                        ].forEach((i) => result.push(i));
                    }
                    break;
                }
                case 'customer.contacts':
                    contacts
                        .filter((contact) => {
                            return !!contact.email;
                        })
                        .map((contact) => {
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: `${contact.firstname || ''} ${contact.lastname} <${
                                    contact.email
                                }>`,
                                value: `contact_${contact.id}`,
                                contact,
                            };
                        })
                        .forEach((i) => result.push(i));
                    break;
                case 'customer.agencies':
                    contracts
                        .reduce((carry, contract) => {
                            if (contract.agencyId && !carry.includes(contract.agencyId)) {
                                carry.push(contract.agencyId);
                            }
                            return carry;
                        }, [])
                        .map((agencyId) => agencies.find((agency) => agency.id === agencyId))
                        .filter((agency) => !!agency)
                        .map((agency) => {
                            return {
                                name: group.name,
                                groupTitle: EMAIL_RECEIVER_GROUPS[group.name] || '',
                                label: agency.matchCode || agency.name,
                                value: `agency_${agency.id}`,
                            };
                        })
                        .forEach((i) => result.push(i));
                    break;
                default:
                    break;
            }
        });

        return result;
    }, [
        emailTemplate,
        emailInboxes,
        receiverGroups,
        contacts,
        customer,
        contracts,
        agencies,
        locations,
    ]);

    function defaultEntity(emailTemplate, receiverGroups, receiverOptions, field) {
        if (!emailTemplate) {
            return null;
        }

        const group = receiverGroups.find((g) => g.id === emailTemplate[field]);
        if (!group) {
            return null;
        }

        const optionGroup = receiverOptions.find((g) => g.name === group.name);

        return optionGroup || null;
    }

    const receiverDefault = useMemo(() => {
        const def = defaultEntity(
            emailTemplate,
            receiverGroups,
            receiverOptions,
            'receiverDefault'
        );

        if (!def) {
            return [];
        }

        return [def.value];
    }, [emailTemplate, receiverGroups, receiverOptions]);

    // TODO duplicate code
    const ccDefault = useMemo(() => {
        if (!emailTemplate) {
            return [];
        }

        const group = receiverGroups.find((g) => g.id === emailTemplate.ccDefault);
        if (!group) {
            return [];
        }

        const optionGroup = receiverOptions.find((g) => g.name === group.name);
        return optionGroup ? [optionGroup.value] : [];
        // FIXME: is this needed?
        // if (!optionGroup) {
        //     return [];
        // }
        //
        // return optionGroup ? [optionGroup.options[0].value] : [];
    }, [emailTemplate, receiverGroups, receiverOptions]);

    const onRefresh = useCallback(
        (setValue, selectedReceiverValue) => {
            const def = defaultEntity(
                emailTemplate,
                receiverGroups,
                receiverOptions,
                'receiverDefault'
            );

            let selectedContact = null;
            if (selectedReceiverValue && selectedReceiverValue[0]) {
                const firstSelected = selectedReceiverValue[0];

                if (firstSelected.indexOf('contact_') === 0) {
                    const selectedId = parseInt(firstSelected.replace('contact_', ''), 10);
                    selectedContact = contacts.find(({ id }) => id === selectedId);
                }
            }

            const contact = selectedContact || def?.contact;

            const { text } = replaceEditorTags(
                emailTemplate?.subject,
                emailTemplate?.text,
                customer,
                currentUser,
                contact,
                contacts,
                locations,
                employees,
                extraEntities,
                contractingPartners,
                t
            );

            setValue(text);
        },
        [
            emailTemplate,
            customer,
            contacts,
            currentUser,
            extraEntities,
            receiverGroups,
            receiverOptions,
            t,
            employees,
            locations,
            contractingPartners,
        ]
    );

    const initialValues = useMemo(() => {
        const def = defaultEntity(
            emailTemplate,
            receiverGroups,
            receiverOptions,
            'receiverDefault'
        );

        const contact = def?.contact;

        console.log(extraEntities);

        const { subject, text } = replaceEditorTags(
            emailTemplate?.subject,
            emailTemplate?.text,
            customer,
            currentUser,
            contact,
            contacts,
            locations,
            employees,
            extraEntities,
            contractingPartners,
            t
        );

        return {
            from:
                emailTemplate && !emailTemplate.emailInboxDefaultEditable
                    ? fromAddressOptionsNotEditable[0].value
                    : fromAddressOptions[defaultEmail].value,
            receiver: prefill?.receivers || receiverDefault || [],
            cc: prefill?.cc || ccDefault,
            bcc: [],
            attachments: prefill?.attachments || allAttachments,
            subject: prefill?.subject || subject || '',
            text: prefill?.text || text || '',
            parentId: prefill?.parentId || null,
            customerId: prefill?.customerId || entityId || null,
        };
    }, [
        fromAddressOptions,
        fromAddressOptionsNotEditable,
        emailTemplate,
        customer,
        contacts,
        receiverDefault,
        ccDefault,
        allAttachments,
        currentUser,
        extraEntities,
        receiverGroups,
        receiverOptions,
        prefill,
        t,
        employees,
        entityId,
        locations,
        defaultEmail,
        contractingPartners,
    ]);

    const handleSubmit = (values) => {
        const resolveEmailAddresses = (receiver) => {
            if (receiver.indexOf('@') !== -1) {
                const regex1 = /<(.*)>/g;
                let matches = regex1.exec(receiver);
                if (matches !== null && matches.length > 1) return matches[1];
                const regex2 = /\((.*)\)/g;
                matches = regex2.exec(receiver);
                if (matches !== null && matches.length > 1) return matches[1];

                let cut = receiver.replace(/.*</, '');
                cut = receiver.replace(/>.*/, '');
                return cut;
            }

            if (!receiver.split) {
                return 'null-mail';
            }

            const [eentity, eentityId] = receiver.split('_', 2);
            switch (eentity) {
                case 'contact':
                    return contacts.find(({ id }) => id === parseInt(eentityId, 10)).email;
                case 'agency': {
                    const defaultAgencyEmployee = employees.find((e) => {
                        return String(e.agencyId) === String(eentityId) && e.isPrimaryContact;
                    });

                    if (defaultAgencyEmployee) {
                        return defaultAgencyEmployee.email;
                    }

                    return 'AgencyHasNoContact';
                }
                case 'internal': {
                    const defaultInternal = emailInboxes.find((e) => {
                        return String(e.id) === String(eentityId);
                    });

                    if (defaultInternal) {
                        return defaultInternal.name;
                    }

                    return 'InternalNotFound';
                }
                case 'location': {
                    const defaultLocationEmployee = employees.find((e) => {
                        return String(e.locationId) === String(eentityId) && e.isPrimaryContact;
                    });

                    if (defaultLocationEmployee) {
                        return defaultLocationEmployee.email;
                    }

                    return 'LocationHasNoContact';
                }
                default:
                    return receiver;
            }
        };
        const agencyIds = (receiver) => {
            const [eentity, eentityId] = receiver.split('_', 2);
            switch (eentity) {
                case 'agency': {
                    return eentityId;
                }
                default:
                    return undefined;
            }
        };
        const preppedValues = {
            ...values,
            receiver: values.receiver.filter((receiver) => !!receiver).map(resolveEmailAddresses),
            cc: values.cc.filter((receiver) => !!receiver).map(resolveEmailAddresses),
            bcc: values.bcc.filter((receiver) => !!receiver).map(resolveEmailAddresses),
            attachments: values.attachments.map(
                (attachment) => attachment.path || attachment.payload
            ),
            use_default_signature: false,
        };
        const preppedExtraEntries = {
            ...extraEntities,
            agencies: values.receiver
                .filter((receiver) => !!receiver)
                .map(agencyIds)
                .filter((id) => !!id),
        };

        return api[EMAIL_RESOURCE].sendEmail(preppedValues).then(() => {
            return Promise.resolve(
                onSent ? onSent({ mail: preppedValues, extraEntities: preppedExtraEntries }) : null
            );
        });
    };

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={sendMailSchema}
            enableReinitialize
        >
            <Grid container spacing={2} alignItems="flex-start">
                {/* LEFT SIDE */}
                <Grid container item xs={9} spacing={2}>
                    <Grid item xs={6}>
                        <Select
                            name="from"
                            label="Absender"
                            options={
                                emailTemplate && !emailTemplate.emailInboxDefaultEditable
                                    ? fromAddressOptionsNotEditable
                                    : fromAddressOptions
                            }
                            disabled={emailTemplate && !emailTemplate.emailInboxDefaultEditable}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiChipSelect
                            name="receiver"
                            label="Empfänger"
                            options={receiverOptions}
                            groupBy={(option) => option.groupTitle}
                            fullWidth
                            disabled={emailTemplate && !emailTemplate.receiverDefaultEditable}
                            creatable
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiChipSelect
                            name="cc"
                            label="Kopie an"
                            options={receiverOptions}
                            groupBy={(option) => option.groupTitle}
                            fullWidth
                            disabled={emailTemplate && !emailTemplate.ccDefaultEditable}
                            creatable
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiChipSelect
                            name="bcc"
                            label="Blindkopie an"
                            options={receiverOptions}
                            groupBy={(option) => option.groupTitle}
                            fullWidth
                            creatable
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.dividerMargin} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput name="subject" label="Betreff" fullWidth onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Editor name="text" showRefreshButton onRefresh={onRefresh} />
                    </Grid>
                </Grid>
                {/* RIGHT SIDE */}
                {/* Attachments */}

                <FileTreeAttachments name="attachments" label="Anhänge" roots={roots} />

                {/* BOTTOM */}
                <Grid item xs={12}>
                    <Divider className={classes.dividerMargin} />
                </Grid>
                <Grid item xs={12} className={classes.end}>
                    <SubmitButton container={submitRef} label="Absenden" startIcon={<SendIcon />} />
                </Grid>
            </Grid>
        </Form>
    );
};

SendMailForm.propTypes = {
    entity: PropTypes.string.isRequired,
    entityId: IdPropType,
    templateId: IdPropType,
    attachments: PropTypes.arrayOf(MailAttachmentPropType),
    prefill: PrefillMailPropType,
    extraEntities: PropTypes.shape({}),
    onSent: PropTypes.func,
    submitRef: RefPropType,
};

SendMailForm.defaultProps = {
    templateId: null,
    attachments: null,
    entityId: null,
    prefill: null,
    extraEntities: null,
    onSent: null,
    submitRef: null,
};

export default SendMailForm;

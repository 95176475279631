import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormActions from 'Modules/form/components/FormActions';
import { Box } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import Form from '../../form/components/Form';
import { IdPropType } from '../../../proptypes/basic';
import DateInput from '../../form/components/DateInput';
import EmployeeSelect from '../../form/components/specialized/EmployeeSelect';
import LocationSelect from '../../form/components/specialized/LocationSelect';
import MultiplierSelect from '../../form/components/specialized/MultipliersSelect';
import TextInput from '../../form/components/TextInput';
import ContactSelect from '../../form/components/specialized/ContactSelect';
import { customerSchema } from '../schema';
import TitleBar from '../../layout/components/TitleBar';
import CustomerTypeSelect from '../../form/components/specialized/CustomerTypeSelect';
import LocationSuggestion from '../../locations/components/LocationSuggestion';
import { useCustomer, storeCustomer, updateCustomer } from '../customersSlice';
import { useContactsByCustomerId, updateContact } from '../../contacts/contactsSlice';
import { apiKeySelector } from '../../../Redux/auth/selectors';
import { getApi } from '../../../Redux/sagas/api';
import { useInitialValues } from '../../form/hooks';
import { useSnackbar } from '../../snackbar/hooks';
import CustomerSetEmptyEmployee from './CustomerSetEmptyEmployee';
import { getCustomerPrintPath } from '../../nav/paths';
import {
    FORWARD_TO_CARENEEDTEAM_RESOURCE,
    CUSTOMER_CONTACTS_NOTES_RESOURCE,
    FORWARD_TO_LOCATION_RESOURCE,
    PRINT_DATASHEET_CUSTOMER_INFO_RESOURCE,
} from '../../api/resources';
import { useCanCreate, useCanWrite } from '../../abilities/hooks';
import { initialNotes } from '../../interestedCustomer/notes';
//import { useUserEmployee } from '../../auth/hooks';
import Switch from '../../form/components/Switch';

const CustomerForm = ({ customerId }) => {
    const [customer, { loading: customerLoading }] = useCustomer(customerId);
    const [contacts, { loading }] = useContactsByCustomerId(customerId);
    const dispatch = useDispatch();
    //const { employee: userEmployee } = useUserEmployee();

    const canCreatePrintDatasheetCustomerInfo = useCanCreate(
        PRINT_DATASHEET_CUSTOMER_INFO_RESOURCE
    );
    const canCreateForwardToLocation = useCanCreate(FORWARD_TO_LOCATION_RESOURCE);
    const canCreateForwardToCareNeedTeam = useCanCreate(FORWARD_TO_CARENEEDTEAM_RESOURCE);
    const canWriteCustomerNotes = useCanWrite(CUSTOMER_CONTACTS_NOTES_RESOURCE);

    const vipContact = contacts && contacts.find(({ isVipContact }) => isVipContact);
    const primaryContact = contacts && contacts.find(({ isPrimaryContact }) => isPrimaryContact);
    const contractingPartner =
        contacts && contacts.find(({ isContractingPartner }) => isContractingPartner);

    const isPremiumCustomer = customer.isPremium;
    /*
    const isZentral =
        useUserEmployee &&
        userEmployee.locationId === parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10);
    */

    const initialValues = useInitialValues(customer, {
        currentType: {
            name: '',
            details: '',
        },
        vipContactId: vipContact ? vipContact.id : '',
        primaryContactId: primaryContact ? primaryContact.id : '',
        contractingPartnerId: contractingPartner ? contractingPartner.id : '',
        employeeId: '',
        employeeFormerId: '',
        employeeContractId: '',
        locationId: '',
        assignedLocationAt: null,
        multiplierId: '',
        notes: initialNotes,
        isPremium: isPremiumCustomer,
    });

    // TODO: rework process to assign another employee
    const token = useSelector(apiKeySelector);
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = useCallback(
        async (values) => {
            if (values.employeeId === 61) {
                const { success } = await getApi(
                    token,
                    `/customers/${customerId}/checkreassign`
                ).catch((error) => {
                    enqueueSnackbar(error.response.body.join(', '), { variant: 'error' });
                    return { success: false };
                });
                if (!success) {
                    return Promise.resolve();
                }
            }

            const calls = [];

            console.log('values=>', values);

            if (values.vipContactId !== initialValues.vipContactId) {
                if (initialValues.vipContactId) {
                    calls.push(
                        dispatch(
                            updateContact({
                                id: initialValues.vipContactId,
                                isVipContact: false,
                            })
                        )
                    );
                }
                if (values.vipContactId) {
                    calls.push(
                        dispatch(
                            updateContact({
                                id: values.vipContactId,
                                isVipContact: true,
                            })
                        )
                    );
                }
            }
            if (values.primaryContactId !== initialValues.primaryContactId) {
                if (initialValues.primaryContactId) {
                    calls.push(
                        dispatch(
                            updateContact({
                                id: initialValues.primaryContactId,
                                isPrimaryContact: false,
                            })
                        )
                    );
                }
                if (values.primaryContactId) {
                    calls.push(
                        dispatch(
                            updateContact({ id: values.primaryContactId, isPrimaryContact: true })
                        )
                    );
                }
            }
            if (values.contractingPartnerId !== initialValues.contractingPartnerId) {
                if (initialValues.contractingPartnerId) {
                    calls.push(
                        dispatch(
                            updateContact({
                                id: initialValues.contractingPartnerId,
                                isContractingPartner: false,
                            })
                        )
                    );
                }
                if (values.contractingPartnerId) {
                    calls.push(
                        dispatch(
                            updateContact({
                                id: values.contractingPartnerId,
                                isContractingPartner: true,
                            })
                        )
                    );
                }
            }
            calls.push(
                dispatch(
                    customerId
                        ? updateCustomer({ ...values, id: customerId })
                        : storeCustomer(values)
                )
            );

            return await Promise.all(calls);
        },
        [dispatch, initialValues, customerId, enqueueSnackbar, token]
    );

    return (
        !loading &&
        !customerLoading && (
            <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={customerSchema}
                enableReinitialize
                subject={customer}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={3}>
                        <TitleBar>Status</TitleBar>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <CustomerTypeSelect
                                    label="Status"
                                    name="currentType.name"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput label="Details" name="currentType.details" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <ContactSelect
                                    customerId={customerId}
                                    label="VIP"
                                    name="vipContactId"
                                    setDefault="false"
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Switch
                                    name="isPremium"
                                    label="Premium Kunde"
                                    labelPlacement="end"
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TitleBar>Hauptkontakte</TitleBar>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ContactSelect
                                    customerId={customerId}
                                    label="Hauptkontakt"
                                    name="primaryContactId"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ContactSelect
                                    customerId={customerId}
                                    label="Vertragspartner"
                                    name="contractingPartnerId"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TitleBar>Kundenbetreuer</TitleBar>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <EmployeeSelect
                                    label="Kundenbetreuer"
                                    name="employeeId"
                                    fullWidth
                                    userLocation
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EmployeeSelect
                                    label="ehemaliger Betreuer"
                                    name="employeeFormerId"
                                    fullWidth
                                    userLocation
                                />
                            </Grid>
                            {customer.employeeId &&
                                !customer.employeeFormerId &&
                                canCreateForwardToCareNeedTeam && (
                                    <Grid item xs={12}>
                                        <CustomerSetEmptyEmployee
                                            customerId={customer.id}
                                            disabled={!canCreateForwardToCareNeedTeam}
                                        />
                                    </Grid>
                                )}
                            <Grid item xs={12}>
                                <EmployeeSelect
                                    label="bei Vertragsabschluss"
                                    name="employeeContractId"
                                    fullWidth
                                    userLocation
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TitleBar>Standort</TitleBar>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <LocationSelect
                                    label="Zugewiesener Standort"
                                    name="locationId"
                                    checkFranchiseMode={true}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocationSuggestion
                                    label="Vorschlag"
                                    customerId={customerId}
                                    fallback="Hamburg"
                                />
                            </Grid>
                            {canCreateForwardToLocation && (
                                <Grid item xs={12}>
                                    <DateInput
                                        label="An Standort weitergeleitet am"
                                        name="assignedLocationAt"
                                        fullWidth
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <MultiplierSelect
                                    label="Zugewiesener Pflegedienst-Partner"
                                    name="multiplierId"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TitleBar>Notizen</TitleBar>
                        <Grid container>
                            <Grid item md={8}>
                                <Box mb={6}>
                                    <TextInput
                                        label="Notizen"
                                        name="notes"
                                        multiline
                                        rows={11}
                                        fullWidth
                                        disabled={!canWriteCustomerNotes}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormActions>
                    {canCreatePrintDatasheetCustomerInfo && (
                        <Link to={getCustomerPrintPath(customerId)}>
                            <Print /> Datenblatt
                        </Link>
                    )}
                </FormActions>
            </Form>
        )
    );
};

CustomerForm.propTypes = {
    customerId: IdPropType,
};

CustomerForm.defaultProps = {
    customerId: null,
};

export default CustomerForm;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';
import PhoneIcon from '@material-ui/icons/Phone';
import { Typography } from '@material-ui/core';
import TitleBar from '../../layout/components/TitleBar';
import { IdPropType } from '../../../proptypes/basic';
import {
    REMINDER_RESOURCE,
    CUSTOMER_REMINDER_RESOURCE}
from '../../api/resources';
import BaseSwitch from '../../form/components/base/BaseSwitch';
import ResourceTable from '../../table/components/resource/ResourceTable';
import { indexPage } from '../../lists/actions';
import DisplayDate from '../../date/components/DisplayDate';
import Note from '../../notes/components/Note';
import Employee from '../../employees/components/Employee';
import { useDialog } from '../../dialogs/components/DialogContext';
import Button from '../../buttons/components/Button';
import { destroyReminder, updateReminder } from '../remindersSlice';
import CustomerType from '../../customers/components/CustomerType';
import { useResourceDialog } from '../../dialogs/hooks';
import CreateResourceIconButton from '../../table/components/resource/CreateResourceIconButton';
import CustomerLink from '../../customers/components/CustomerLink';
import { useCanDelete } from '../../abilities/hooks';

const initialOrderBy = [['dueAt', 'asc']];

const getColumns = ({ customerId, forUser }) => [
    {
        key: 'dueAt',
        label: 'Fällig am',
        as: 'value',
        component: DisplayDate,
    },
    {
        key: 'category',
        label: 'Kategorie',
        component: ({ category, data: { categoryOther } }) =>
            categoryOther ? `${category} [${categoryOther}]` : category,
    },
    {
        key: 'customerId',
        label: 'Kunde',
        component: ({ customerId, data: { customerNames } }) =>
            customerNames && customerNames.length > 0 ? (
                <CustomerLink customerId={customerId} />
            ) : (
                <Typography variant="inherit" color="textSecondary">
                    (Ohne Kunde)
                </Typography>
            ),
        hidden: !!customerId || forUser,
    },
    {
        key: 'note',
        label: 'Notiz',
        as: 'note',
        component: Note,
    },
    {
        key: 'customerType',
        accessor: 'customerId',
        label: 'Kunden-Art',
        component: CustomerType,
        hidden: !!customerId,
    },
    {
        key: 'createdAt',
        label: 'Erstellt am',
        as: 'value',
        component: DisplayDate,
    },
    {
        key: 'createdById',
        label: 'Erstellt von',
        as: 'employeeId',
        component: Employee,
    },
];

const Reminders = ({ customerId, filter, listId, label, forUser }) => {
    const [includeDone, setIncludeDone] = useState(false);
    const dispatch = useDispatch();
    const { openVisitDialog } = useDialog();

    const canDeleteReminder = useCanDelete(CUSTOMER_REMINDER_RESOURCE);

    const handleFilter = useCallback(
        (e, newValue) => {
            setIncludeDone(newValue);
        },
        [setIncludeDone]
    );

    const fetchParams = useMemo(() => (customerId ? { customerId } : {}), [customerId]);

    useEffect(() => {
        if (filter) {
            /**
             * If we want all reminders we completely ignore the `doneAt` field.
             * Set `doneAt` only if we explicitly want only reminders that are not done yet.
             */
            const preppedParams = !includeDone ? { doneAt: 'null', ...fetchParams } : fetchParams;

            dispatch(indexPage(listId, 1, preppedParams));
        }
    }, [dispatch, listId, fetchParams, filter, includeDone]);

    const handleEdit = useResourceDialog(REMINDER_RESOURCE, { customerId });

    const handleDone = useCallback(
        (reminderId) => dispatch(updateReminder({ id: reminderId, doneAt: new Date() })),
        [dispatch]
    );

    const handleVisit = useCallback(
        (reminderId, { dueAt }) => {
            openVisitDialog({
                customerId,
                initialValues: {
                    notes: `Visite ${moment(dueAt).locale('de').format('MMMM')}`,
                },
                reminderId,
            });
        },
        [customerId, openVisitDialog]
    );

    const handleDelete = useCallback(
        (reminderId) => dispatch(destroyReminder({ id: reminderId })),
        [dispatch]
    );

    const handleDeleteDisabled = () => {
        return !canDeleteReminder;
    };

    const extraActions = useMemo(
        () => [
            {
                key: 'done',
                action: ({ data }) => (
                    <Button
                        startIcon={<DoneIcon />}
                        onClick={() => handleDone(data.id)}
                        disabled={!!data.doneAt}
                    >
                        Erledigt
                    </Button>
                ),
            },
            {
                key: 'visit',
                action: ({ data }) =>
                    data.category === 'Visite' && (
                        <Button
                            startIcon={<PhoneIcon />}
                            onClick={() => handleVisit(data.id, { dueAt: data.dueAt })}
                        >
                            Visite
                        </Button>
                    ),
            },
        ],
        [handleDone, handleVisit]
    );

    const columns = useMemo(() => getColumns({ customerId, forUser }), [customerId, forUser]);

    let filterfilter;
    if (filter) {
        filterfilter = {
            key: 'filter',
            action: (
                <BaseSwitch
                    value={includeDone}
                    name="includeDone"
                    onChange={handleFilter}
                    label="Erledigte Reminder anzeigen"
                    color="secondary"
                />
            ),
        };
    }

    return (
        <>
            <TitleBar
                aside={[
                    filterfilter,
                    {
                        key: 'create',
                        action: (
                            <CreateResourceIconButton
                                listId={listId}
                                resource={REMINDER_RESOURCE}
                                onClick={handleEdit}
                                size="small"
                            />
                        ),
                    },
                ]}
            >
                {label}
            </TitleBar>
            <ResourceTable
                columns={columns}
                resource={REMINDER_RESOURCE}
                listId={listId}
                fetchParams={fetchParams}
                onEdit={handleEdit}
                onDelete={canDeleteReminder?handleDelete:null}
                actions={extraActions}
                initialOrderBy={initialOrderBy}
                deleteDisabled={handleDeleteDisabled}
            />
        </>
    );
};

Reminders.propTypes = {
    listId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    customerId: IdPropType,
    filter: PropTypes.bool,
    forUser: PropTypes.bool,
};

Reminders.defaultProps = {
    customerId: null,
    filter: false,
    forUser: false,
};

export default Reminders;

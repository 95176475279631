import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { IdPropType } from '../../../proptypes/basic';
import { updateEmployee, useEmployee } from '../employeesSlice';
import { attachmentsToFieldValue } from '../../files/utils';
import DocumentsForm from '../../documents/components/DocumentsForm';
import { useDialogControl } from '../../dialogs/components/DialogControlContext';
import { useCallbackRef } from '../../hooks';
import Dialog from '../../dialogs/components/Dialog';
import DialogFormActions from '../../dialogs/components/DialogFormActions';

const SignatureDialog = ({ employeeId }) => {
    const ref = useCallbackRef();
    const [employee, { loading, initialized }] = useEmployee(employeeId);
    const { onClose } = useDialogControl();

    const dispatch = useDispatch();

    const initialValues = useMemo(
        () => ({
            documents: attachmentsToFieldValue(employee.documents),
        }),
        [employee]
    );

    const handleSubmit = useCallback(
        (values) => dispatch(updateEmployee(values)).then(onClose),
        [dispatch, onClose]
    );

    return (
        <Dialog>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Mitarbeiter Signatur</DialogTitle>

            <DialogContent>
                <DocumentsForm
                    name="documents"
                    label="Signatur hinzufügen"
                    initialPath="System/employeeSignatures"
                    entity={employee}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    loading={loading}
                    initialized={initialized}
                    submitRef={ref}
                    max={1}
                />
            </DialogContent>

            <DialogFormActions ref={ref} />
        </Dialog>
    );
};

SignatureDialog.propTypes = {
    employeeId: IdPropType.isRequired,
};

SignatureDialog.defaultProps = {};

export default SignatureDialog;

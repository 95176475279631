export const CUSTOMER_ENTITY = 'Customer';

export const CONTRACT_ENTITY = 'CustomerContract';

export const NURSE_SUGGESTION_ENTITY = 'NurseSuggestion';

export const NURSE_REQUEST_ENTITY = 'NurseRequest';

export const TRAVEL_DETAILS_ENTITY = 'TravelDetails';

export const INFOMATERIAL_ENTITY = 'Infomaterial';

export const QUESTIONNAIRE_ENTITY = 'Questionnaire';

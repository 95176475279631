import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isBefore } from 'date-fns';
import { SubjectPropType } from '../../../abilities/proptypes';
import SendMailButton from './SendMailButton';
import { NURSE_REQUEST_ENTITY } from '../../../mailTemplates/entities';
import { IdPropType } from '../../../../proptypes/basic';
import { PrefillMailPropType } from '../../../mails/proptypes';
// import { useCallbackFunc } from '../../../hooks';
import CareNeedSelect from '../../../careNeeds/components/CareNeedSelect';
import { storeNurseRequest } from '../../../nurseRequests/nurseRequestsSlice';
import { YupId } from '../../../form/schema';
// import { selectAllEmployees } from '../../../employees/employeesSlice';
import { indexQuestionnaires } from '../../../questionnaires/questionnairesSlice';
import { indexContacts } from '../../../contacts/contactsSlice';
import { createQuestionnaireToken } from '../../../questionnaires/utils';

const SendNurseRequestButton = ({
    name,
    disabled,
    customerId,
    careNeedId,
    onSent,
    subject,
    action,
    className,
    variant,
    color,
    size,
    label,
    fullWidth,
    prefill,
    ellipsis,
}) => {
    const dispatch = useDispatch();
    // const employees = useSelector(selectAllEmployees);

    // TODO: make this available as a dynamic attachment
    //       or move it to SendMailDialog if the PatientEB-Tag is used somewhere else
    const prepareExtraEntities = useCallback(() => {
        /*
         * Somewhat hacky but gets the job done..
         *
         * A better way to do this would be to extract the useFooById-hook-functionality into
         * a separate async function (that doesn't use hooks) that can be used here
         * (to prevent unnecessary loading of contacts and questionnaires
         * if they are present already in the redux store anyway)
         * */
        return dispatch(indexContacts({ customerId, isPatient: true }))
            .then(({ data: patientContacts }) =>
                Promise.all(
                    patientContacts.map((patientContact) =>
                        dispatch(indexQuestionnaires({ contactId: patientContact.id }))
                    )
                )
            )
            .then((questionnairesByContacts) => {
                const result = {
                    questionnaires: questionnairesByContacts
                        .map(({ data: questionnairesByContact }) =>
                            questionnairesByContact.reduce((carry, _questionnaire) => {
                                if (!carry) {
                                    return _questionnaire;
                                }
                                return isBefore(
                                    new Date(carry.createdAt),
                                    new Date(_questionnaire.createdAt)
                                )
                                    ? _questionnaire
                                    : carry;
                            }, null)
                        )
                        .reduce(
                            (carry, questionnaire) => ({
                                ...carry,
                                [questionnaire.contactId]: questionnaire,
                            }),
                            {}
                        ),
                };
                const querstionnairsarray = Object.values(result.questionnaires);

                return Promise.all([
                    result,
                    ...querstionnairsarray.map((quest) =>
                        createQuestionnaireToken(customerId, quest.contactId, false, null, dispatch, 1)
                    ),
                ]);
            })
            .then((ret) => {
                const quests = ret.shift();
                quests.tags = ret.map((token, index) => ({
                    tag: 'EBLINKANONYM' + (index ? index + 1 : ''),
                    value: token,
                }));
                return quests;
            });
    }, [customerId, dispatch]);

    const extraFields = useMemo(
        () => [
            {
                name: 'careNeedId',
                component: CareNeedSelect,
                label: 'BK-Bedarf',
                customerId,
                validate: YupId().required('Pflichtfeld'),
                initial: careNeedId,
            },
        ],
        [customerId, careNeedId]
    );

  /*
    const getAgencyIdsForContracts = useCallbackFunc((emails) =>
        emails
            .map((email) => employees.find((contact) => contact.email === email))
            .filter((employee) => !!employee)
            .reduce((carry, employee) => {
                if (!carry.includes(employee.agencyId)) {
                    carry.push(employee.agencyId);
                }
                return carry;
            }, [])
    );
    */

    const handleSent = useCallback(
        async ({ mail, extraEntities }) => {
            //const agencyIds = getAgencyIdsForContracts([...mail.receiver, ...mail.cc, ...mail.bcc]);
            const agencyIds = extraEntities.agencies;

            // TODO: check open nurse requests?
            const results = await Promise.all(
                agencyIds.map((agencyId) =>
                    dispatch(
                        storeNurseRequest({
                            careNeedIds: [extraEntities.careNeedId],
                            customerId,
                            agencyId,
                        })
                    )
                )
            );

            if (onSent) {
                await onSent(results);
            }
        },
        [customerId, dispatch, onSent]
    );

    return (
        <SendMailButton
            name={name}
            variant={variant}
            color={color}
            disabled={disabled}
            action={action}
            subject={subject}
            entity={NURSE_REQUEST_ENTITY}
            entityId={customerId}
            onSent={handleSent}
            extraEntities={prepareExtraEntities}
            extraFields={extraFields}
            className={className}
            size={size}
            label={label || 'BK-Anfrage versenden'}
            fullWidth={fullWidth}
            prefill={prefill}
            ellipsis={ellipsis}
        />
    );
};

SendNurseRequestButton.propTypes = {
    name: PropTypes.string,
    customerId: IdPropType.isRequired,
    careNeedId: IdPropType,
    onSent: PropTypes.func,
    disabled: PropTypes.bool,
    subject: SubjectPropType,
    action: PropTypes.string,
    variant: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.node,
    fullWidth: PropTypes.bool,
    prefill: PrefillMailPropType,
    ellipsis: PropTypes.bool,
};

SendNurseRequestButton.defaultProps = {
    name: null,
    careNeedId: null,
    onSent: null,
    disabled: false,
    subject: null,
    action: null,
    variant: undefined,
    color: undefined,
    size: undefined,
    className: null,
    label: null,
    fullWidth: false,
    prefill: null,
    ellipsis: false,
};

export default SendNurseRequestButton;

import React, { useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import MultiSelect from '../../form/components/MultiSelect';
import Employee from '../../employees/components/Employee';
import { useEmployees } from '../../employees/employeesSlice';
import { IdPropType } from '../../../proptypes/basic';
import { useUserEmployee } from '../../auth/hooks';
import { selectEmployeesByRelatedId } from '../../employees/selectors';

export const ALL_EMPLOYEE_MAILS = 'all';
export const UNASSIGNED_MAILS = 'unassigned';
const REMEMBER_BEFORE_ALL = false;

const equals = (a, b) => {
    if (a.length !== b.length) {
        return false;
    }

    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter((e) => e === v).length;
        const bCount = b.filter((e) => e === v).length;
        if (aCount !== bCount) {
            return false;
        }
    }

    return true;
};

const MailEmployeeMultiselect = ({
    name,
    autoSubmit,
    filterBy,
    locationId,
    agencyId,
    userLocation,
}) => {
    const [{ value }, , { setValue }] = useField(name);
    const { loading } = useEmployees();
    const employees = useSelector((state) =>
        selectEmployeesByRelatedId(state, { locationId, agencyId })
    );
    const { employee: userEmployee } = useUserEmployee();
    const [previousValue, setPreviousValue] = useState(value);
    const [beforeAllSelect, setBeforeAllSelect] = useState([]);
    const [lastChangeRemovedOption, setLastChangeRemovedOption] = useState(false);

    const filtered = useMemo(() => {
        let _filtered = employees.filter((employee) => employee.status === 'active');
        if (userLocation && userEmployee && userEmployee.locationId) {
            _filtered = _filtered.filter((employee) => employee.locationId !== null);
            if (
                userEmployee.locationId !== parseInt(process.env.REACT_APP_LOCATION_HAMBURG_ID, 10)
            ) {
                _filtered = _filtered.filter(
                    (employee) => employee.locationId === userEmployee.locationId
                );
            }
        }
        if (filterBy) {
            _filtered = _filtered.filter(filterBy);
        }

        return _filtered;
    }, [employees, filterBy, userEmployee, userLocation]);

    const options = useMemo(
        () => [
            { label: 'Nicht zugewiesen', value: UNASSIGNED_MAILS },
            { label: 'Alle', value: ALL_EMPLOYEE_MAILS },
            ...filtered.map((employee) => ({
                label: <Employee key={employee.id} employeeId={employee.id} />,
                value: employee.id,
            })),
        ],
        [filtered]
    );

    useEffect(() => {
        if (value.includes(ALL_EMPLOYEE_MAILS)) {
            let newValues = [];

            // Check if someone was removed.
            if (previousValue.includes(ALL_EMPLOYEE_MAILS) && value.length < previousValue.length) {
                newValues = [
                    ...filtered.map((employee) => employee.id).filter((id) => value.includes(id)),
                    ...(value.includes(UNASSIGNED_MAILS) ? [UNASSIGNED_MAILS] : []),
                ];

                setLastChangeRemovedOption(true);
            } else {
                newValues = [
                    ...filtered.map((employee) => employee.id),
                    ...(value.includes(UNASSIGNED_MAILS) ? [UNASSIGNED_MAILS] : []),
                    ALL_EMPLOYEE_MAILS,
                ];

                setLastChangeRemovedOption(false);
            }

            if (!equals(value, newValues)) {
                if (REMEMBER_BEFORE_ALL) {
                    setBeforeAllSelect(previousValue);
                }
                setValue(newValues);
            }
        } else if (!lastChangeRemovedOption && previousValue.includes(ALL_EMPLOYEE_MAILS)) {
            setValue(REMEMBER_BEFORE_ALL ? beforeAllSelect : []);
        }

        setPreviousValue(value);
    }, [
        value,
        filtered,
        setValue,
        previousValue,
        setPreviousValue,
        beforeAllSelect,
        setBeforeAllSelect,
        setLastChangeRemovedOption,
        lastChangeRemovedOption,
    ]);

    return (
        <>
            <MultiSelect
                name={name}
                size="small"
                label="Zugewiesen"
                disabled={loading}
                options={options}
                autoSubmit={autoSubmit}
                fullWidth
            />
        </>
    );
};

MailEmployeeMultiselect.propTypes = {
    name: PropTypes.string.isRequired,
    autoSubmit: PropTypes.bool,
    filterBy: PropTypes.func,
    locationId: IdPropType,
    agencyId: IdPropType,
    userLocation: PropTypes.bool,
};

MailEmployeeMultiselect.defaultProps = {
    autoSubmit: false,
    filterBy: null,
    locationId: null,
    agencyId: null,
    userLocation: false,
};

export default MailEmployeeMultiselect;

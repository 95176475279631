import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import { CardContainer, CardContent, CardExpansion, CardTable } from 'react-kanban';
import { Box, makeStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CareNeedHeader from './CareNeedHeader';
import Typography from '../../Modules/mui/Components/Typography';
import { NurseRequestDataPropType } from '../../proptypes/careneeds';
import { UserPropType } from '../../proptypes/users';
import { isExternal } from '../../helpers/auth';
import NurseRequestCardContent from './NurseRequestCardContent';
import { getUnpresentedNurseRequests } from '../../Modules/kanban/careNeedDashboard';
import {
    NURSE_SUGGESTION_RESOURCE,
    NURSE_REQUEST_RESOURCE,
} from '../../Modules/api/resources';
import { useResourceDialog } from '../../Modules/dialogs/hooks';
import SendNurseRequestButton from '../../Modules/buttons/components/specialized/SendNurseRequestButton';
import Button from '../../Modules/buttons/components/Button';
import Agency from '../../Modules/agencies/components/Agency';
import { useCanWrite } from '../../Modules/abilities/hooks';

const useStyles = makeStyles({
    adjustSpace: {
        paddingTop: 6,
    },
});

const getNurseRequestRow = (nurseRequest, external) => {
    const hoursAgo = moment().diff(moment(nurseRequest.createdAt), 'hours');
    const isOverdue = hoursAgo >= 48; // TODO: how long?
    const nurseSuggestion = nurseRequest.suggestions.filter(
        (suggestion) => suggestion.rejectedAt === null
    );

    return {
        key: nurseRequest.id,
        color: isOverdue && 'error',
        cells: [
            {
                key: 'count',
                label: <Typography variant="body2">{nurseSuggestion.length}</Typography>,
            },
            {
                key: 'agency',
                label: (
                    <Typography ellipsis variant="body2">
                        <Agency agencyId={nurseRequest.agency} />
                    </Typography>
                ),
                flex: 1,
            },
            !external && {
                key: 'action',
                label: 'Nachfassen',
                onClick: () => {
                    console.log('TODO');
                },
                disabled: true,
            },
            {
                key: 'hoursAgo',
                label: (
                    <Typography variant="body2">
                        {hoursAgo > 99
                            ? `${Math.min(Math.floor(hoursAgo / 24), 99)} d`
                            : `${hoursAgo} h`}
                    </Typography>
                ),
            },
        ].filter((row) => row),
    };
};

const NurseRequestCard = ({
    data,
    expanded,
    toggleExpand,
    inTransition,
    currentUser,
    showSendQuestionnaire,
}) => {
    const classes = useStyles();
    const unpresented = useMemo(() => getUnpresentedNurseRequests(data.nurseRequests), [data]);

    const canWriteNurseRequest = useCanWrite(NURSE_REQUEST_RESOURCE);

    const handleNurseSuggestion = useResourceDialog(NURSE_SUGGESTION_RESOURCE, {
        careNeedId: data.id,
        customerId: data.customer.id,
    });
    return (
        <CardContainer error={data.urgent}>
            <CareNeedHeader
                customer={data.customer}
                urgent={data.urgent}
                date={data.careStartAt}
                external={isExternal(currentUser)}
                showId
            />
            <CardContent>
                <CardTable
                    data={data.nurseRequests.map((nurseRequest) =>
                        getNurseRequestRow(nurseRequest, isExternal(currentUser))
                    )}
                />

                <Box className={unpresented.length === 0 ? classes.adjustSpace : ''}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <SendNurseRequestButton
                                disabled={!canWriteNurseRequest}
                                customerId={data.customer.id}
                                careNeedId={data.id}
                                variant="outlined"
                                fullWidth
                                ellipsis
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                disabled={!canWriteNurseRequest}
                                variant={
                                    data.nurseRequests.find(
                                        (nurseRequest) => nurseRequest.suggestionsCount > 0
                                    )
                                        ? 'outlined'
                                        : 'contained'
                                }
                                fullWidth
                                ellipsis
                                color="primary"
                                onClick={handleNurseSuggestion}
                            >
                                BK-Vorschlag
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>

            {unpresented.length > 0 && (
                <CardExpansion
                    expanded={expanded}
                    onToggle={toggleExpand}
                    inTransition={inTransition}
                >
                    <NurseRequestCardContent
                        requests={unpresented}
                        onDetail={handleNurseSuggestion}
                        external={isExternal(currentUser)}
                        customerId={data.customer.id}
                    />
                </CardExpansion>
            )}
        </CardContainer>
    );
};

NurseRequestCard.propTypes = {
    data: NurseRequestDataPropType.isRequired,
    currentUser: UserPropType.isRequired,
    expanded: PropTypes.bool.isRequired,
    toggleExpand: PropTypes.func.isRequired,
    inTransition: PropTypes.bool.isRequired,
};

export default NurseRequestCard;

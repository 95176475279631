import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import BaseSwitch from './base/BaseSwitch';
import { useFieldFast } from '../hooks';

const Switch = ({ name, label, color, disabled, labelPlacement, size, helperText, onChange }) => {
    const [{ value }, meta, { setValue }] = useFieldFast(name);

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue);
            if (onChange) {
                onChange(event, newValue);
            }
        },
        [setValue, onChange]
    );

    return (
        <BaseSwitch
            label={label}
            labelPlacement={labelPlacement}
            name={name}
            value={value}
            onChange={handleChange}
            color={color}
            disabled={disabled}
            size={size}
            error={(meta.touched && meta.error) || null}
            helperText={helperText}
        />
    );
};

Switch.propTypes = {
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.string,
    helperText: PropTypes.string,
};

Switch.defaultProps = {
    label: '',
    color: 'primary',
    disabled: false,
    labelPlacement: 'start',
    size: 'medium',
    helperText: null,
    onChange: null,
};

export default Switch;

//import React, { useMemo } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType, RefPropType } from '../../../proptypes/basic';
import TextInput from '../../form/components/TextInput';
import LocationStatusSelect from '../../form/components/specialized/LocationStatusSelect';
import CountrySelect from '../../form/components/specialized/CountrySelect';
import { selectLocationById } from '../locationsSlice';
import { LOCATION_RESOURCE } from '../../api/resources';
import { useInitialValues, useResourceSubmit } from '../../form/hooks';
import { locationSchema } from '../schema';
import ManageLocationSignatureButton from '../../buttons/components/specialized/ManageLocationSignatureButton';

const LocationForm = ({ locationId, onDone, submitRef }) => {
    const location = useSelector((state) => selectLocationById(state, locationId));
    const initialValues = useInitialValues(location, {
        name: '',
        matchCode: '',
        notes: '',
        status: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        iban: '',
        bic: '',
        name1: '',
        phone: '',
        email: '',
        homepage: '',
        email_disclaimer: '',
        email_notification: '',
   });

    const handleSubmit = useResourceSubmit(locationId, LOCATION_RESOURCE, null, { onDone });

    /*
    const extraActions = useMemo(
        () => [
            {
                key: 'signature',
                action: ({ data: { id } }) => <ManageLocationSignatureButton employeeId={id} />,
            },
        ],
        []
    );
*/

    return (
        <Form
            initialValues={initialValues}
            validationSchema={locationSchema}
            onSubmit={handleSubmit}
            subject={location || LOCATION_RESOURCE}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput name="name" label="Vermittler Name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput name="name1" label="Vermittler Name 1" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput name="address" label="Anschrift" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="phone" label="Vermittlertelefon" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="homepage" label="Vermittler Homepage" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="notes"
                                label="Notizen"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={10}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocationStatusSelect name="status" label="Status" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextInput name="zip" label="PLZ" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput name="city" label="Stadt" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <CountrySelect name="country" label="Land" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="email" label="Vermittler E-Mail" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="email_notification" label="Benachrichtigungen E-Mail" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput name="matchCode" label="Matchcode" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="iban" label="IBAN" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput name="bic" label="BIC" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <ManageLocationSignatureButton locationId={locationId} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                name="email_disclaimer"
                                label="Email-Disclaimer"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={10}
                            />
                        </Grid>
                    </Grid>
                </Grid>


                {submitRef ? (
                    <SubmitButton container={submitRef} />
                ) : (
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <SubmitButton />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Form>
    );
};

LocationForm.propTypes = {
    locationId: IdPropType,
    onDone: PropTypes.func,
    submitRef: RefPropType,
};

LocationForm.defaultProps = {
    locationId: null,
    onDone: () => null,
    submitRef: null,
};

export default LocationForm;

import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IdPropType } from '../../../proptypes/basic';
import FileTreeProvider from '../../files/components/FileTree/FileTreeProvider';
import Form from '../../form/components/Form';
import { updateEmail, useEmail } from '../emailsSlice';
import { useCustomer } from '../../customers/customersSlice';
import {
    asAbsolutePath,
    attachmentsToFieldValue,
    fieldValueToAttachments,
} from '../../files/utils';
import DropzoneFiles from '../../files/components/Dropzone/DropzoneFiles';
import SubmitButton from '../../form/components/SubmitButton';
import FormTouched from '../../form/components/FormTouched';

const MailAttachments = ({ mailId }) => {
    const [mail] = useEmail(mailId);
    const [customer] = useCustomer(mail?.customer_id);
    const cloudRoot = asAbsolutePath(customer?.cloudFolder);
    const dispatch = useDispatch();

    const initialValues = useMemo(
        () => ({
            attachments: attachmentsToFieldValue(mail?.attachments || [], 'path', 'name'),
        }),
        [mail]
    );

    const handleSubmit = useCallback(
        ({ attachments }, { resetForm }) => {
            return dispatch(
                updateEmail({ id: mailId, attachments: fieldValueToAttachments(attachments) })
            ).then(resetForm);
        },
        [dispatch, mailId]
    );

    return (
        <FileTreeProvider>
            <Form initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                {mail.customer_id ? (
                    <DropzoneFiles
                        name="attachments"
                        root={cloudRoot}
                        movable
                        openable
                        hideFiles
                        withNurseSearch
                    />
                ) : (
                    <DropzoneFiles name="attachments" root={cloudRoot} openable hideFiles />
                )}

                <FormTouched>
                    <SubmitButton />
                </FormTouched>
            </Form>
        </FileTreeProvider>
    );
};

MailAttachments.propTypes = {
    mailId: IdPropType.isRequired,
};

MailAttachments.defaultProps = {};

export default MailAttachments;
